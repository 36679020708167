.icons_fijos {
  position: fixed;
  top: 160px;
  right: 0;
  z-index: 69;
  display: flex;
  flex-direction: column;
  color: white;
  text-shadow: 5px 5px 5px black;
}

.icons_fijos .input-group {
  position: absolute !important;
  bottom: 10px;
  right: 70px;
  width: 300px;
}

.icons_fijos input {
  background-color: white !important;
  width: 200px !important;
  border-radius: 20px 0 0 25px !important;
  padding: 0 5px 0 5px !important;
  flex: 1 1;
  margin: 0 !important;
}

.input-group .btn {
  height: 100%;
  width: 50px;
  overflow: hidden;
  background-color: white !important;
  border: none !important;
}

.bi .bi-search {
  width: 50px;
  height: 100%;
}

.position-sticky {
  top: 0;
  z-index: 69;
}
@media (min-width: 521px) {
  .container_inicio .icons_fijos i {
    margin: 10px;
    font-size: 3em !important;
  }
}

@media (min-width: 300px) and (max-width: 520px) {
  .container_inicio .icons_fijos i {
    margin: 10px;
    font-size: 2em !important;
  }

  .icons_fijos .input-group {
    position: absolute !important;
    bottom: 10px;
    right: 70px;
    width: 200px;
  }
}

.cardCompleta {
  color: white;
}

thead {
  border: none !important;
}

.icon_girar {
  transform: rotateY(180deg);
}

.icons_fijos img {
  height: 60px;
  z-index: 0;
  cursor: pointer;
}

.input-group > .form-control,
.input-group > .form-floating,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1% !important;
  min-width: 0;
}

.InputBuscar {
  visibility: hidden;
}
