footer {
    height: auto;
    color: white;
    background-color: #16344c;
    text-align: center;
    padding: 20px;
    font-weight: 100 !important;
    max-width: 2000px;
    margin: auto;
  }
  
  footer p {
    margin: 0 !important;
    font-weight: 300;

  }
  
  @media (min-width: 300px) and (max-width: 800px) {
    footer {
        height: auto;
        color: white;
        background-color: #16344c;
        text-align: center;
        padding: 15px;
        font-size: 10px;
      }
  }
