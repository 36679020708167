@media (min-width: 1000px) {
  .container_inicio {
    position: relative;
    max-width: 2000px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100vh);
    background-repeat: no-repeat;
  }

  .container_inicio h1 {
    position: absolute;
    bottom: 0;
    right: 50px;
    color: white;
    font-weight: 900;
    font-size: 7em;
    word-spacing: 20px;
    letter-spacing: 10px;
    text-shadow: 1px 1px 5px black;
    user-select: none;
  }

  .redes_sociales {
    position: absolute;
    bottom: 150px;
    left: 2rem;
    font-size: 2.3rem;
    color: white;
    bottom: 20px;

    text-shadow: 3px 3px 5px black;
  }

  .redes_sociales i {
    margin: 200px;
  }

  .campus h1 {
    color: white;
    font-weight: bold;
    text-align: start;
    font-size: 3.5em;
  }

}

@media (min-width: 521px) and (max-width: 1000px) {
  .container_inicio {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 50vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .redes_sociales {
    position: absolute;
    bottom: 100px;
    left: 2rem;
    font-size: 2.3rem;
    color: white;
    text-shadow: 3px 3px 5px black;
    bottom: 20px;
  }

  .redes_sociales i {
    margin: 200px;
  }

  .campus h1 {
    color: white;
    font-weight: bold;
    text-align: start;
    font-size: 2.5rem;
  }

  .container_inicio h1 {
    position: absolute;
    bottom: 0;
    right: 10px;
    color: white;
    font-weight: 900;
    font-size: 4em;
    word-spacing: 20px;
    letter-spacing: 5px;
    text-shadow: 1px 1px 5px black;
    margin: 0;
    margin-bottom: 0;
    user-select: none;

  }


}

@media (min-width: 300px) and (max-width: 520px) {
  .container_inicio {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 50vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .redes_sociales {
    position: absolute;
    bottom: 50px;
    left: 1rem;
    font-size: 1.3rem;
    color: white;
    bottom: 20px;
  }

  .campus h1 {
    color: white;
    font-weight: bold;
    text-align: start;
    font-size: 2em;
  }


  .container_inicio h1 {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: white;
    font-weight: 900;
    font-size: 2em;
    word-spacing: 20px;
    letter-spacing: 5px;
    text-shadow: 1px 1px 5px black;
    margin: 0;
    margin-bottom: 0;
    user-select: none;

  }

}

.custom-shape-divider-top-1665758554 {
  position: relative;
}

.flecha_animation i {
  position: absolute;
  color: white;
}

.custom-shape-divider-top-1665758554 h1 {
  margin: 0;
}

@keyframes down {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.campus {
  position: absolute;
  top: 0;
  height: 80%;
  display: flex;
  align-items: center;
  padding-left: 30px;
}

.redes_sociales a {
  color: white !important;
}

.container_inicio video{
  object-fit: fill;
  height: 100%;
}