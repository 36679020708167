.containerimg {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6f6f6;
  height: 600px;
}

.logodeco {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 48% 48% 50% 50%;
}

.logodeco img {
  height: 250px;
  width: 250px;
  border-radius: 10px 10px 50% 50%;
}

.span_barra {
  padding: 0;
  height: 150px;
  width: 30px;
  color: #ff6961;
}

.itemDeco {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-right: 50px;
  height: 500px;
}

.containerimg .Titulo {
  padding: 40px;
  width: -webkit-fill-available;
  height: 500px;
  display: flex;
  justify-content: center;
  padding-top: 5rem;
  flex-direction: column;
}

.containerimg .Titulo h3{
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: start;
}

@media (min-width: 300px) and (max-width: 520px) {
  .containerimg .Titulo h3{
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: start;
  }
}
.Titulo p {
  color: gray;
  font-size: 14px;
  text-align: start;
  margin: 0 !important;
}

.container_Car {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.container_Car .card {
  margin: 20px;
  height: 275px;
  font-size: 14px;
  overflow: hidden;
  width: 22rem !important;
  text-align: justify;
}

.titulo_servi {
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
}

#tituloServicios {
  font-size: 2rem;
  background-color: #ff6961;
  width: fit-content;
  padding: 5px 70px 5px 70px;
  margin-top: 40px;
  border-radius: 25px;
  box-shadow: 0 2px 5px black;
}

@media (min-width: 300px) and (max-width: 520px) {
  .containerimg {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    background-color: #f6f6f6;
    height: fit-content;
  }

  .containerimg .Titulo {
    padding-top: 1rem;
  }
}
