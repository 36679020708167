.containerheader {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100;
  color: white;
  font-size: 13px;
  height: 75px;

}

.contenidoNav {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.containerheader img {
  height: 50px;
}

.imagen img {
  height: 60px;
}

.barra {
  background-color: #77383E;
}

.activenav {
  border-bottom: 1px solid white;
}

.containerheader i {
  display: none !important;
}

@media (min-width: 300px) and (max-width: 950px) {
  .contenidoNav {
    display: none;
  }

  .containerheader {
    justify-content: space-between;
    padding: 10px;
  }

  .containerheader i {
    display: block !important;
    font-size: 30px;
  }

  .recorridoVirtual {
    height: 50% !important;
  }
}