.container_ofertaVirtual .items_card {
  display: flex;
  gap: 20px;

  justify-items: flex-start;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 20px;
}

.containerTituloOferta {
  text-align: center;
  display: flex;
  justify-content: center;
}




@media (min-width: 601px) {
  .container_OfertaVirtual .containerTituloOferta p {
    font-size: 50px;
    color: white;
    font-weight: 700;
    padding-top: 20px;
    letter-spacing: 5px;
    user-select: none;
    text-shadow: 1px 1px 5px black;
    letter-spacing: 10px;

  }
}

@media (min-width: 200px) and (max-width: 600px) {
  .container_OfertaVirtual .containerTituloOferta p {
    position: relative;
    padding: 20px;
    font-size: 30px;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 3px black;
    user-select: none;
    padding-top: 30px;
  }

  .ofertasflex {
    display: flex;
    align-items: center;
    padding: 30px;
    flex-direction: column;
  }

  .container_ofertaVirtual .items_card {
    display: flex;
    gap: 20px;

    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin-left: 0px;
    margin-top: 30px;
  }
}

.container_card {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.container_ofertaVirtual {
  background-image: url("../img/Recursos/fondoferta.png");
  background-size: 100% 100%;
  max-width: 2000px;
  margin: auto;
}

.containerFiltro {
  position: relative;
  display: flex;
  border: 1px solid black !important;
  width: 250px;
  flex-direction: column;
  padding: 35px;
  height: auto;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 5px 0px rgb(0, 0, 0);
  border-radius: 30px;
  background-color: rgba(70, 22, 22, 0.705);
}


.container_filtro svg {
  margin-left: 10px;
}

.itemInput button {
  margin: 10px;
  height: 60px;
  width: 200px;
}

.itemInput label {
  color: #ffffff;
  font-weight: bold;
}



.container_filtro h1 {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 0;
}

.container_filtro img {
  height: 125px;
}

.icontop {
  position: absolute;
  left: 0;
  top: -10px;
}




[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: fill !important;
}

.itemInput {
  background-color: none !important;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  display: flex;
  text-align: start;
  margin-top: 20px;
}

.itemInput label {
  font-size: 20px;
  cursor: pointer;
}

.itemInput input {
  cursor: pointer;

  transform: scale(2);
  accent-color: #77383E;
}

.ofertasflex {
  display: flex;
  align-items: center;
  padding: 30px;
}

.itemInputFacultad {
  display: none;
}

.itemInputFacultad input {
  transform: scale(1) !important;
}

.itemInputFacultad label {
  font-size: 10px;
}


.cardCompleta .imagen.ofertas img {
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 25px;
  z-index: 2;
}

.cardCompleta .imagen.ofertas img {
  box-shadow: none !important;
}

.cardCompleta .imagen.ofertas::before {
  content: "";
  border: none !important;
}

#citacion img::before {
  content: "Hola";
  color: white;
  position: absolute;
  z-index: 20;
  width: 100%;
  height: 100%;
}

.ofertas P {
  position: absolute;
  z-index: 20;
  display: flex;
  width: 100%;
  height: 100%;
  top: -10px;
  align-items: center;
  font-weight: bold;
  padding: 10px;
}

.container_OfertaVirtual .cardCompleta {
  margin: 0 !important;
}


.imagen_mapa img {
  position: relative;
  z-index: 99;

}

.imagen_mapa div {
  z-index: 100;
}