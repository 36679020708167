.personas_container2 {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    overflow: hidden;
    }

    .itemsSliderTalleristas2  {
        height: 500px;
    }
    

    .personas_container2 h1{
        text-align: center;
        color: #76383d;
        font-size: 35px !important;
        letter-spacing: 2px;
        text-shadow: 2px 4px 3px black;
        padding-top: 40px;
        font-weight: bold;
        user-select: none;
    }