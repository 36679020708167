#result_search div {
  padding: 10px;
  margin: 10px;
  width: auto;
  box-shadow: 1px 1px 5px gray;
  height: 70px;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

#result_search a{
    color: #77383e;
    font-weight: bold;
}

#result_search a:hover{
    color: #583135;
    font-weight: bold;
    transform: scale(1.02);
    transition: 1s;
}

#cantidad_search h1 {
  text-align: start;
  padding: 1rem;
  font-weight: bold;
  font-size: 2rem;
  background-color: #77383e;
  width: fit-content;
  border-radius: 0px 0 20px 0px;
  border-radius: 1px solid gray;
  box-shadow: 1px 1px 5px gray;
  color: white;
  margin-top: 80px;
}

.containerBuscador{
    height: auto;
}



@media (min-width: 200px) and (max-width: 600px) {
  #cantidad_search h1 {
    text-align: start;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.3rem;
    background-color: #77383e;
    width: fit-content;
    border-radius: 0px 0 20px 0px;
    border-radius: 1px solid gray;
    box-shadow: 1px 1px 5px gray;
    color: white;
  }
  
  }