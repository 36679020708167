.modal_mapa {
  background-color: #00000063 !important;
  backdrop-filter: blur(10px);
}

.modal-content {
  background-color: transparent !important;
  border: none !important;
}

.modal-content img {
  height: 550px;
}

.modal-footer {
  background-color: transparent !important;
  border-top: none !important;
  justify-content: center;
}

.modal-dialog-centered {
  height: 100vh !important;
  justify-content: center;
}

.modal-dialog {
  max-width: 100% !important;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -20px) !important;
  margin: 0 !important;
}








.modal-content {
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0 !important;
}

.modal {
  max-height: 100vh !important;
}

.lista_kabai {
  position: absolute;
  bottom: 0;
  visibility: hidden;
}


@media (min-width: 601px) and (max-width: 920px) {

  .modal_unico {
    height: auto !important;
  }

  .iconosPisos {
    position: absolute;
    bottom: -80px;
    right: 150px;
    display: flex;
    gap: 10px;
  }

  .modal-body {
    flex: none !important;
    width: 100%;
    flex-direction: column;
    height: 800px;
  }

  .posgrados2,
  .posgrados3img {
    height: 400px !important;
  }

  .bibliotecapiso1,
  .bibliotecapiso2,
  .bibliotecapiso4,
  .Daba1Img,
  .Medios2 {
    height: 400px !important;
    width: 600px;
    margin-top: 20px;
  }

  .imgKabai {
    height: 400px;
    width: 600px;
  }

  .IMGkabai3 {
    height: 400px;
    width: 600px;
  }

  .imgDabar2 {
    height: 400px;
    width: 600px;
  }

  .ale3img {
    height: 400px !important;
  }

  .Dabar3 {
    height: 400px !important;
  }

  .kabai1Imagenm,
  .ImagenKabai,
  .Humanitas1Imagen,
  .imagenKabai2 {
    width: 600px !important;
  }

  #Kabai1 i,
  #Kabai2 i,
  #Dabar1 i,
  #Dabar3 i,
  #Dabar2 i,
  #Kabai3 i,
  #Ale1 i,
  #Ale2 i,
  #Ale3 i,
  #Humanitas1 i,
  #Humanitas2 i,
  #Capsi i,
  #Buena1 i,
  #Biblioteca1 i,
  #Biblioteca2 i,
  #Biblioteca3 i,
  #Biblioteca4 i,
  #Posgrados1 i,
  #Posgrados2 i,
  #Posgrados3 i,
  #Medios2 i,
  #Buena2 i {
    font-size: 25px;
    position: absolute;
    color: brown;
    text-shadow: 0 0 5px white;
  }

  #Ale3 .fcbi {
    bottom: 280px;
    right: 210px;
  }

  .capsiImagen {
    width: 600px !important;
  }


  #Posgrados3 .coorpc {
    bottom: 270px;
    left: 300px;
  }

  #Posgrados3 .coorpc:hover::before {
    content: "Coordinación de Especialización en Psicología Clínica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorcgh {
    bottom: 310px;
    left: 370px;
  }

  #Posgrados3 .coorcgh:hover::before {
    content: "Coordinación de Especialización en Gestión Humana de las Organizaciones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorgpi {
    bottom: 350px;
    left: 440px;
  }

  #Posgrados3 .coorgpi:hover::before {
    content: "Coordinación de Especialización en Gestión de Proyectos de Innovación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Posgrados3 .cooredumatica {
    bottom: 400px;
    left: 350px;
  }

  #Posgrados3 .cooredumatica:hover::before {
    content: "Coordinación de Especialización en Edumática";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorpsisocial {
    bottom: 350px;
    left: 300px;
  }

  #Posgrados3 .coorpsisocial:hover::before {
    content: "Coordinación de Especialización en Psicología Social Comunitaria y Acción Psicosocial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai1 .bañoskabai1 {
    bottom: 260px;
    left: 460px;
  }



  #Kabai1 .foto {
    bottom: 200px;
    right: 230px;
  }

  #Kabai1 .foto:hover::before {
    content: "Fotocopiadora";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .bañoskabai1:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar2 .ingsis {
    bottom: 260px;
    left: 280px;
  }

  #Dabar2 .ingsis:hover::before {
    content: "Dirección de Ingeniería en Sistemas y Telecomunicaciones, Dirección del Departamento de Ciencias Básicas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale1 .bañosale {
    bottom: 310px;
    left: 190px;
  }

  #Ale1 .bañosale:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .bañoshumanitas {
    bottom: 240px;
    left: 240px;
  }

  #Humanitas1 .bañoshumanitas:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .fcbi:hover::before {
    content: "Facultad de Ciencias Básicas e Ingeniería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;

    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .aulav1 {
    bottom: 240px;
    right: 390px;
  }

  #Kabai3 .aulav1:hover::before {
    content: "Aula virtual 1";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .archivo {
    bottom: 330px;
    left: 120px;
  }

  #Humanitas2 .archivo:hover::before {
    content: "Archivo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Humanitas1 .salones2 {
    bottom: 250px;
    right: 160px;
  }


  #Humanitas1 .dyp {
    bottom: 160px;
    right: 220px;
  }

  #Humanitas1 .dyp:hover::before {
    content: "Dirección de Planeación y Calidad";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;

    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .almacen {
    bottom: 200px;
    right: 320px;
  }

  #Humanitas1 .almacen:hover::before {
    content: "Almacén";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;

    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Humanitas1 .salones2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .bigdata {
    bottom: 150px;
    left: 180px;
  }

  #Kabai3 .bigdata:hover::before {
    content: "Laboratorio de big data";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  .lista_kabai {
    left: 0;
    font-weight: bold;
    text-align: start;
    background-color: white;
    padding: 10px 30px;
    border-radius: 20px;
  }

  .Ale2 {
    height: 400px !important;
  }

  #Posgrados2 .secrehuma {
    bottom: 370px;
    right: 330px;
  }

  #Posgrados2 .direccionhuma {
    bottom: 380px;
    right: 250px;
  }

  #Posgrados2 .posgradopegaogia {
    bottom: 350px;
    right: 160px;
  }

  #Posgrados2 .profesionalpeda {
    bottom: 290px;
    right: 210px;
  }

  #Posgrados2 .direccionlic {
    bottom: 260px;
    right: 280px;
  }

  #Posgrados2 .profesionalpeda:hover::before {
    content: "Profesional de Posgrados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .posgradopegaogia:hover::before {
    content: "Posgrados Pedagogía y Desarrollo Humano";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .direccionhuma:hover::before {
    content: "Dirección Humanidades";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .secrehuma:hover::before {
    content: "Secretaría Humanidades y LER";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .direccionlic:hover::before {
    content: "Dirección de Licenciatura de Educación Religiosa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .cafeteria {
    bottom: 120px;
    right: 180px;
  }

  #Posgrados1 .piso1posgrados {
    bottom: 260px;
    right: 330px;
  }

  #Posgrados2 .piso2posgrados {
    bottom: 330px;
    right: 270px;
  }

  #Medios2 .Cabinas {
    bottom: 210px;
    right: 100px;
  }

  #Medios2 .radio {
    bottom: 300px;
    right: 220px;
  }

  #Medios2 .salonmedio2 {
    bottom: 280px;
    right: 390px;
  }

  #Medios2 .edicionmedio2 {
    bottom: 180px;
    right: 300px;
  }

  #Medios2 .Cabinas:hover::before {
    content: "Cabina";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Medios2 .radio:hover::before {
    content: "Radio UCP";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);

  }

  #Medios2 .salonmedio2:hover::before {
    content: "Salón";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);

  }

  #Medios2 .edicionmedio2:hover::before {
    content: "Salones de Edición";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Posgrados2 .piso2posgrados:hover::before {
    content: "Dep. Humanidades y Programa LER";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Posgrados1 .piso1posgrados:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }


  #Biblioteca1 .referencia {
    bottom: 250px;
    right: 370px;
  }

  #Biblioteca1 .referencia:hover::before {
    content: "Referencia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .papeleria {
    bottom: 130px;
    right: 110px;
  }

  #Kabai1 .salones {
    bottom: 300px;
    right: 360px;
  }

  #Kabai1 .vrp2 {
    bottom: 400px;
    right: 420px;
  }

  #Kabai1 .mercadeo {
    bottom: 260px;
    right: 270px;
  }

  #Dabar1 .auditorio {
    bottom: 350px;
    right: 300px;
  }

  #Dabar1 .vrp {
    bottom: 320px;
    right: 210px;
  }

  #Dabar1 .consejos {
    bottom: 300px;
    right: 140px;
  }

  #Dabar1 .pb {
    bottom: 180px;
    right: 150px;
  }

  #Dabar1 .salonesd1 {
    bottom: 240px;
    right: 180px;
  }

  #Dabar1 .salonesd1:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cie {
    bottom: 250px;
    left: 270px;
  }

  #Kabai3 .cubiculos {
    bottom: 220px;
    left: 280px;
  }

  #Kabai3 .laboratorio {
    bottom: 190px;
    left: 220px;
  }

  #Ale1 .cafeteriaroja {
    bottom: 200px;
    left: 400px;
  }

  #Kabai3 .ciesalones {
    bottom: 230px;
    left: 180px;
  }

  #Buena1 .piso2 {
    bottom: 240px;
    right: 160px;
  }

  #Biblioteca3 .salag {
    bottom: 330px;
    right: 550px;
  }

  #Biblioteca3 .salap {
    bottom: 350px;
    right: 360px;
  }

  #Biblioteca3 .salagrupal {
    bottom: 270px;
    right: 210px;
  }

  #Biblioteca3 .cubiculosb {
    bottom: 210px;
    right: 590px;
  }




  #Biblioteca3 .salag:hover::before {
    content: "Sala general";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .salap:hover::before {
    content: "Sala de profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .salagrupal:hover::before {
    content: "Sala grupal";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .cubiculosb:hover::before {
    content: "Cubículos profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena1 .piso2:hover::before {
    content: "Piso 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
    transition: 1s;
    cursor: pointer;

  }

  #Kabai3 .salonesk3 {
    bottom: 400px;
    left: 400px;
  }

  #Ale1 .banco {
    bottom: 270px;
    left: 180px;
  }

  #Ale1 .gmercadeo {
    bottom: 380px;
    left: 280px;
  }

  #Ale1 .tiendau {
    bottom: 290px;
    left: 390px;
  }



  #Humanitas1 .gestionh {
    bottom: 300px;
    right: 280px;
  }

  #Humanitas1 .practicas {
    bottom: 350px;
    right: 390px;
  }

  #Humanitas1 .capilla {
    bottom: 200px;
    right: 140px;
  }

  #Humanitas1 .hsalones {
    bottom: 250px;
    left: 180px;
  }

  #Humanitas2 .ciuc {
    bottom: 360px;
    right: 380px;
  }

  #Humanitas2 .comunicaciones {
    bottom: 290px;
    right: 250px;
  }


  #Humanitas2 .direccionadm {
    bottom: 290px;
    left: 200px;
  }

  #Humanitas2 .estudiante {
    bottom: 180px;
    left: 420px;
  }

  #Capsi .capsi {
    bottom: 200px;
    left: 400px;
  }

  #Capsi .enfermeria {
    bottom: 220px;
    left: 160px;
  }



  #Buena1 .prodilab {
    bottom: 400px;
    right: 360px;
  }



  #Buena1 .arquitectura {
    bottom: 290px;
    right: 290px;
  }

  #Buena1 .medio {
    bottom: 250px;
    right: 230px;
  }

  #Humanitas1 .patio {
    bottom: 250px;
    left: 320px;
  }


  #Humanitas1 .estudiantes {
    bottom: 180px;
    left: 430px;
  }

  #Capsi .cubicapsi {
    bottom: 220px;
    left: 220px;
  }

  #Capsi .cubicapsi:hover::before {
    content: "Cubículos CAPSI";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas1 .patio:hover::before {
    content: "Patio interior";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas1 .estudiantes:hover::before {
    content: "Entrada Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .oval1 {
    bottom: 130px;
    right: 350px;
  }

  #Buena2 .salonesbuena2 {
    bottom: 230px;
    right: 260px;
  }

  #Buena2 .gym {
    bottom: 380px;
    right: 430px;
  }

  #Kabai2 .salonesk2 {
    bottom: 320px;
    right: 400px;
  }

  #Kabai2 .lab_neuro {
    bottom: 220px;
    right: 190px;
  }

  #Kabai2 .lab_psi {
    bottom: 170px;
    right: 110px;
  }

  #Kabai2 .programa_DA {
    bottom: 250px;
    right: 230px;
  }

  #Kabai2 .DCE {
    bottom: 150px;
    right: 170px;
  }

  #Kabai2 .acuario {
    bottom: 160px;
    right: 200px;
  }

  #Kabai2 .baños {
    bottom: 280px;
    right: 160px;
  }

  #Kabai2 .inter {
    bottom: 120px;
    right: 170px;
  }

  #Dabar2 .gradas {
    bottom: 410px;
    right: 500px;
  }

  #Dabar2 .salamultiple {
    bottom: 410px;
    right: 380px;
  }

  #Dabar2 .lab_fisica {
    bottom: 230px;
    right: 290px;
  }

  #Dabar2 .labii {
    bottom: 290px;
    right: 360px;
  }



  #Dabar2 .gradas:hover::before {
    content: "Teatro al aire libre";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .salamultiple:hover::before {
    content: "Sala múltiple";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .lab_fisica:hover::before {
    content: "Laboratorio de física";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .labii:hover::before {
    content: "Laboratorio de ingeniería industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .inter:hover::before {
    content: "Internacionalización";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .salonesk3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar2 .teatrino {
    bottom: 320px;
    right: 430px;
  }

  #Dabar2 .centrocultura {
    bottom: 340px;
    right: 370px;
  }

  #Dabar2 .cubiculosdabar {
    bottom: 370px;
    right: 460px;
  }

  #Dabar2 .oval2 {
    bottom: 150px;
    right: 220px;
  }

  #Dabar2 .salonesd2 {
    bottom: 250px;
    right: 200px;
  }

  #Ale2 .financiera {
    bottom: 340px;
    right: 400px;
  }

  #Ale2 .tesoreria {
    bottom: 390px;
    right: 320px;
  }

  #Ale2 .salaprofe {
    bottom: 400px;
    right: 140px;
  }

  #Ale2 .vice {
    bottom: 280px;
    right: 400px;
  }

  #Ale2 .rectoria {
    bottom: 240px;
    right: 340px;
  }

  #Ale2 .pastoral {
    bottom: 320px;
    right: 220px;
  }

  #Ale2 .pastoral:hover::before {
    content: "Pastoral Universitaria";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Ale2 .admisiones {
    bottom: 240px;
    right: 250px;
  }

  #Ale3 .financiera2 {
    bottom: 340px;
    right: 360px;
  }

  #Ale3 .gh {
    bottom: 410px;
    right: 240px;
  }

  #Ale3 .sc1 {
    bottom: 370px;
    right: 240px;
  }

  #Ale3 .sc2 {
    bottom: 220px;
    right: 200px;
  }

  #Kabai3 .emprendimiento {
    bottom: 200px;
    left: 120px;
  }

  #Kabai3 .plataforma {
    bottom: 210px;
    left: 390px;
  }

  #Dabar3 .salonesd3 {
    bottom: 320px;
    left: 200px;
  }

  #Dabar3 .oval3 {
    bottom: 320px;
    left: 100px;
  }

  #Dabar3 .salamac {
    bottom: 330px;
    left: 270px;
  }

  #Biblioteca1 .bibliocafe {
    bottom: 420px;
    right: 470px;
  }

  #Biblioteca1 .infantil {
    bottom: 280px;
    right: 190px;
  }

  #Biblioteca1 .dinv {
    bottom: 350px;
    right: 490px;
  }

  #Biblioteca2 .prestamos {
    bottom: 280px;
    right: 440px;
  }

  #Biblioteca2 .emeroteca {
    bottom: 270px;
    right: 230px;
  }

  #Biblioteca4 .pinacoteca {
    bottom: 330px;
    right: 480px;
  }

  #Biblioteca4 .pinacoteca:hover::before {
    content: "Pinacoteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Biblioteca2 .prestamos:hover::before {
    content: "Circulación y préstamos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca2 .emeroteca:hover::before {
    content: "Hemeroteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Biblioteca1 .bibliocafe:hover::before {
    content: "Bibliocafé";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca1 .infantil:hover::before {
    content: "Sala infantil";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca1 .dinv:hover::before {
    content: "Dirección de Investigaciónes e Innovación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Dabar3 .salamac:hover::before {
    content: "Sala mac";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar3 .oval3:hover::before {
    content: "Oval 3";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar3 .salonesd3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .emprendimiento:hover::before {
    content: "Sala de emprendimiento";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .plataforma:hover::before {
    content: "Plataforma innovarte";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .financiera2:hover::before {
    content: "Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .gh:hover::before {
    content: "Gestión Tecnológica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale3 .sc1:hover::before {
    content: "Sala de sistemas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale3 .sc2:hover::before {
    content: "Sala de Sistemas 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .financiera:hover::before {
    content: "Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .tesoreria:hover::before {
    content: "Tesorería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .rectoria:hover::before {
    content: "Rectoría";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .vice:hover::before {
    content: "Vicerrectoría Académica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .salaprofe:hover::before {
    content: "Cubículos de profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .admisiones:hover::before {
    content: "Admisiones y Registro";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .salonesd2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .oval2:hover::before {
    content: "Oval 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .cubiculosdabar:hover::before {
    content: "Cubículos insonorizados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .centrocultura:hover::before {
    content: "Centro de Actividades Culturales";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .teatrino:hover::before {
    content: "Teatrino";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .salonesk2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .lab_neuro:hover::before {
    content: "Laboratorio de neurociencia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .lab_psi:hover::before {
    content: "Laboratorio de psicología";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .programa_DA:hover::before {
    content: "Decanatura de Facultad de Ciencias Básicas e Ingeniería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .baños:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .DCE:hover::before {
    content: "Decanatura de Facultad de Ciencias Económicas y Administrativas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .acuario:hover::before {
    content: "Oficinas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena2 .gym:hover::before {
    content: "Gimnasio";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena2 .salonesbuena2:hover::before {
    content: "Taller de diseño industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .prodilab:hover::before {
    content: "Prodilab";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .arquitectura:hover::before {
    content: "Taller de diseño industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena1 .medio:hover::before {
    content: "Centro de medios";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .oval1:hover::before {
    content: "Oval 1";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .ciuc:hover::before {
    content: "Proyección Social y Centro de Idiomas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .comunicaciones:hover::before {
    content: "Coordinación de Comunicaciones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .psicologia {
    bottom: 210px;
    left: 310px;
  }


  #Humanitas2 .psicologia:hover::before {
    content: "Dirección de Psicología";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .hfche {
    bottom: 180px;
    left: 270px;
  }


  #Humanitas2 .hfche:hover::before {
    content: "Facultad de Ciencias Humanas, Sociales y de la Educación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Capsi .capsi:hover::before {
    content: "CAPSI";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Capsi .enfermeria:hover::before {
    content: "Enfermería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .direccionadm:hover::before {
    content: "Dirección Administrativa y Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai1 .cafeteria:hover::before {
    content: "Cafetería azul";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .papeleria:hover::before {
    content: "Papelería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .salones:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .vrp2:hover::before {
    content: "Vicerrectoría Proyecto de Vida    ";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .mercadeo:hover::before {
    content: "Dirección de Programa de Mercadeo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .salvip {
    bottom: 180px;
    right: 270px;
  }

  #Kabai3 .salvip:hover::before {
    content: "Sala VIP";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .auditorio:hover::before {
    content: "Auditorio Dabar";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .vrp:hover::before {
    content: "Centro de Familia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .pb:hover::before {
    content: "Punto de Bolsa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .consejos:hover::before {
    content: "Sala de consejos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cie:hover::before {
    content: "Centro de Innovación Educativa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cubiculos:hover::before {
    content: "Cubículos de estudio";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .laboratorio:hover::before {
    content: "Laboratorio de visualización avanzada";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .ciesalones:hover::before {
    content: "Aula virtual y módulos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .cafeteriaroja:hover::before {
    content: "Cafetería roja";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .banco:hover::before {
    content: "Banco AV Villas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .gmercadeo:hover::before {
    content: "Gestión de Mercadeo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .tiendau:hover::before {
    content: "Tienda universitaria";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .financiera:hover::before {
    content: "Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .tesoreria:hover::before {
    content: "Tesorería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .gestionh:hover::before {
    content: "Gestión Humana";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .practicas:hover::before {
    content: "Prácticas y Graduados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .capilla:hover::before {
    content: "Capilla: San José";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .hsalones:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .estudiante:hover::before {
    content: "Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }
}

@media (min-width: 921px) {
  .modal-body {
    flex: none !important;
    height: 500px;
    width: 100%;
  }

  #Posgrados3 .coorpc {
    bottom: 170px;
    right: 300px;
  }


  #Posgrados3 .coorcgh {
    bottom: 200px;
    right: 230px;
  }

  #Posgrados3 .coorpc:hover::before {
    content: "Coordinación de Psicología Clínica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorcgh:hover::before {
    content: "Coordinación de Comunicación y Gestión Humana";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  .iconosPisos {
    font-size: 2em;
    color: white;
    cursor: pointer;
  }

  .iconosPisos {
    position: absolute;
    bottom: 60px;
    left: 30px;
    display: flex;
    gap: 10px;
  }

  .pisosPosgrados {
    bottom: 100px !important;
  }

  .pisosHumanitas div {
    width: 170px !important;
  }

  .pisosHumanitas div:nth-child(3) {
    width: 350px !important;
  }

  .pisosHumanitas {
    bottom: 60px;
    left: 20px !important;
    grid-template-columns: 170px 170px !important;
  }

  .pisosBuenaNueva {
    bottom: 50px !important;
  }

  .modal-body img.bibliotecapiso2 {
    height: 300px !important;
  }

  .modal-body img.bibliotecapiso1 {
    height: 400px !important;
  }

  .modal-body img:nth-child(2n) {
    height: 500px;
    width: 600px !important;
  }





  #Kabai1 i,
  #Kabai2 i,
  #Dabar1 i,
  #Dabar3 i,
  #Dabar2 i,
  #Kabai3 i,
  #Ale1 i,
  #Ale2 i,
  #Ale3 i,
  #Humanitas1 i,
  #Humanitas2 i,
  #Capsi i,
  #Buena1 i,
  #Biblioteca1 i,
  #Biblioteca2 i,
  #Biblioteca3 i,
  #Biblioteca4 i,
  #Posgrados1 i,
  #Posgrados2 i,
  #Posgrados3 i,
  #Medios2 i,
  #Buena2 i {
    font-size: 30px;
    position: absolute;
    color: brown;
    text-shadow: 0 0 5px white;
  }

  #Kabai1 .cafeteria {
    bottom: 100px;
    right: 200px;
  }

  #Kabai1 .papeleria {
    bottom: 110px;
    right: 110px;
  }

  #Kabai3 .bigdata {
    bottom: 120px;
    left: 580px;
  }

  #Humanitas1 .bañoshumanitas {
    bottom: 210px;
    left: 620px;
  }

  #Humanitas1 .bañoshumanitas:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .bañoskabai1 {
    bottom: 230px;
    left: 860px;
  }




  #Posgrados3 .coorpc {
    bottom: 170px;
    left: 700px;
  }

  #Posgrados3 .coorpc:hover::before {
    content: "Coordinación de Especialización en Psicología Clínica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorcgh {
    bottom: 210px;
    left: 770px;
  }

  #Posgrados3 .coorcgh:hover::before {
    content: "Coordinación de Especialización en Gestión Humana de las Organizaciones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorgpi {
    bottom: 260px;
    left: 850px;
  }

  #Posgrados3 .coorgpi:hover::before {
    content: "Coordinación de Especialización en Gestión de Proyectos de Innovación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Posgrados3 .cooredumatica {
    bottom: 320px;
    left: 760px;
  }

  #Posgrados3 .cooredumatica:hover::before {
    content: "Coordinación de Especialización en Edumática";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorpsisocial {
    bottom: 290px;
    left: 700px;
  }

  #Posgrados3 .coorpsisocial:hover::before {
    content: "Coordinación de Especialización en Psicología Social Comunitaria y Acción Psicosocial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai1 .bañoskabai1:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .bañosale {
    bottom: 300px;
    left: 570px;
  }

  #Kabai3 .aulav1 {
    bottom: 220px;
    right: 390px;
  }

  #Kabai3 .aulav1:hover::before {
    content: "Aula virtual 1";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale1 .bañosale:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .bigdata:hover::before {
    content: "Laboratorio de big data";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar2 .ingsis {
    bottom: 230px;
    left: 690px;
  }

  #Dabar2 .ingsis:hover::before {
    content: "Dirección de Ingeniería en Sistemas y Telecomunicaciones, Dirección del Departamento de Ciencias Básicas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Kabai1 .salones {
    bottom: 260px;
    right: 340px;
  }

  #Kabai1 .mercadeo {
    bottom: 210px;
    right: 270px;
  }





  #Humanitas1 .dyp {
    bottom: 130px;
    right: 200px;
  }

  #Humanitas1 .dyp:hover::before {
    content: "Dirección de Planeación y Calidad";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .almacen {
    bottom: 170px;
    right: 330px;
  }

  #Humanitas1 .almacen:hover::before {
    content: "Almacén";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;

    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados2 .secrehuma {
    bottom: 300px;
    right: 280px;
  }

  #Posgrados2 .direccionhuma {
    bottom: 320px;
    right: 210px;
  }

  #Posgrados2 .posgradopegaogia {
    bottom: 250px;
    right: 120px;
  }

  #Posgrados2 .profesionalpeda {
    bottom: 200px;
    right: 180px;
  }

  #Posgrados2 .direccionlic {
    bottom: 160px;
    right: 240px;
  }

  #Posgrados2 .profesionalpeda:hover::before {
    content: "Profesional de Posgrados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .posgradopegaogia:hover::before {
    content: "Posgrados Pedagogía y Desarrollo Humano";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .direccionhuma:hover::before {
    content: "Dirección Humanidades";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .secrehuma:hover::before {
    content: "Secretaría Humanidades y LER";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .direccionlic:hover::before {
    content: "Dirección de Licenciatura de Educación Religiosa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Medios2 .Cabinas {
    bottom: 200px;
    right: 80px;
  }

  #Medios2 .radio {
    bottom: 350px;
    right: 230px;
  }

  #Medios2 .salonmedio2 {
    bottom: 320px;
    right: 400px;
  }

  #Ale3 .fcbi {
    bottom: 190px;
    right: 260px;
  }


  #Ale3 .fcbi:hover::before {
    content: "Facultad de Ciencias Básicas e Ingeniería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }


  #Medios2 .edicionmedio2 {
    bottom: 180px;
    right: 350px;
  }

  #Medios2 .Cabinas:hover::before {
    content: "Cabina";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Medios2 .radio:hover::before {
    content: "Radio UCP";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);

  }

  #Medios2 .salonmedio2:hover::before {
    content: "Salón";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);

  }

  #Medios2 .edicionmedio2:hover::before {
    content: "Salones de Edición";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }



  #Posgrados1 .piso1posgrados {
    bottom: 250px;
    right: 300px;
  }

  #Posgrados1 .piso1posgrados:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }


  #Capsi .enfermeria {
    bottom: 190px;
    right: 450px;
  }

  #Capsi .capsi {
    bottom: 170px;
    right: 200px;
  }

  #Kabai1 .vrp2 {
    bottom: 360px;
    right: 420px;
  }


  #Posgrados2 .piso2posgrados {
    bottom: 230px;
    right: 240px;
  }

  #Posgrados2 .piso2posgrados:hover::before {
    content: "Dep. Humanidades y Programa LER";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Biblioteca1 .referencia {
    bottom: 170px;
    right: 370px;
  }

  #Biblioteca1 .referencia:hover::before {
    content: "Referencia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .auditorio {
    bottom: 300px;
    right: 290px;
  }

  #Biblioteca3 .salag {
    bottom: 300px;
    right: 450px;
  }

  #Biblioteca3 .salap {
    bottom: 300px;
    right: 270px;
  }

  #Biblioteca3 .salagrupal {
    bottom: 240px;
    right: 170px;
  }

  #Biblioteca3 .cubiculosb {
    bottom: 180px;
    right: 470px;
  }




  #Biblioteca3 .salag:hover::before {
    content: "Sala general";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .salap:hover::before {
    content: "Sala de profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .salagrupal:hover::before {
    content: "Sala grupal";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .cubiculosb:hover::before {
    content: "Cubículos profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .vrp {
    bottom: 260px;
    right: 210px;
  }

  #Dabar1 .consejos {
    bottom: 240px;
    right: 130px;
  }

  #Dabar1 .pb {
    bottom: 80px;
    right: 140px;
  }

  #Kabai3 .cie {
    bottom: 230px;
    left: 680px;
  }

  #Kabai3 .cubiculos {
    bottom: 180px;
    left: 680px;
  }

  #Kabai3 .laboratorio {
    bottom: 150px;
    left: 620px;
  }

  #Ale1 .cafeteriaroja {
    bottom: 210px;
    left: 800px;
  }

  #Kabai3 .ciesalones {
    bottom: 210px;
    left: 580px;
  }

  #Ale1 .banco {
    bottom: 250px;
    left: 550px;
  }

  #Ale1 .gmercadeo {
    bottom: 330px;
    left: 680px;
  }

  #Ale1 .tiendau {
    bottom: 270px;
    left: 740px;
  }


  #Humanitas1 .gestionh {
    bottom: 270px;
    right: 280px;
  }

  #Humanitas1 .practicas {
    bottom: 320px;
    right: 390px;
  }

  #Humanitas1 .capilla {
    bottom: 180px;
    right: 120px;
  }

  #Humanitas1 .hsalones {
    bottom: 230px;
    right: 420px;
  }

  #Humanitas1 .salones2 {
    bottom: 250px;
    right: 200px;
  }

  #Humanitas1 .salones2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .estudiantes {
    bottom: 150px;
    right: 180px;
  }

  #Humanitas1 .patio {
    bottom: 220px;
    right: 280px;
  }

  #Humanitas2 .estudiante {
    bottom: 160px;
    right: 200px;
  }

  #Humanitas2 .archivo {
    bottom: 310px;
    left: 520px;
  }

  #Humanitas2 .archivo:hover::before {
    content: "Archivo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .ciuc {
    bottom: 320px;
    right: 380px;
  }

  #Humanitas2 .comunicaciones {
    bottom: 260px;
    right: 210px;
  }

  #Humanitas2 .psicologia {
    bottom: 180px;
    right: 350px;
  }


  #Humanitas2 .hfche {
    bottom: 160px;
    left: 680px;
  }


  #Humanitas2 .hfche:hover::before {
    content: "Facultad de Ciencias Humanas, Sociales y de la Educación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas2 .direccionadm {
    bottom: 250px;
    right: 420px;
  }

  #Buena1 .prodilab {
    bottom: 370px;
    right: 360px;
  }

  #Buena1 .arquitectura {
    bottom: 280px;
    right: 280px;
  }

  #Buena1 .medio {
    bottom: 240px;
    right: 200px;
  }

  #Buena1 .oval1 {
    bottom: 120px;
    right: 320px;
  }

  #Buena1 .piso2 {
    bottom: 220px;
    right: 150px;
    animation: mymove 3s infinite;
    transition: .7s;

  }

  @keyframes mymove {
    0% {
      transform: scale(1.0);
    }

    100% {
      transform: scale(1.2);
    }
  }

  #Buena1 .piso2:hover::before {
    content: "Piso 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
    transition: 0.1s;
    cursor: pointer;

  }

  #Buena2 .salonesbuena2 {
    bottom: 210px;
    right: 230px;
  }

  #Buena2 .gym {
    bottom: 380px;
    right: 420px;
  }

  #Kabai2 .salonesk2 {
    bottom: 300px;
    right: 400px;
  }

  #Kabai2 .lab_neuro {
    bottom: 200px;
    right: 190px;
  }

  #Kabai2 .lab_psi {
    bottom: 150px;
    right: 110px;
  }

  #Kabai2 .programa_DA {
    bottom: 220px;
    right: 230px;
  }

  #Kabai2 .DCE {
    bottom: 130px;
    right: 170px;
  }

  #Kabai2 .acuario {
    bottom: 120px;
    right: 220px;
  }

  #Kabai2 .inter {
    bottom: 90px;
    right: 170px;
  }

  #Dabar1 .salonesd1 {
    bottom: 220px;
    right: 250px;
  }

  #Dabar1 .salonesd1:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .inter:hover::before {
    content: "Internacionalización";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .salonesk3 {
    bottom: 320px;
    right: 220px;
  }

  #Kabai3 .salonesk3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .baños {
    bottom: 250px;
    right: 170px;
  }

  #Dabar2 .teatrino {
    bottom: 280px;
    right: 410px;
  }

  #Dabar2 .centrocultura {
    bottom: 280px;
    right: 320px;
  }

  #Dabar2 .cubiculosdabar {
    bottom: 330px;
    right: 460px;
  }

  #Dabar2 .oval2 {
    bottom: 120px;
    right: 230px;
  }

  #Dabar2 .salonesd2 {
    bottom: 210px;
    right: 200px;
  }

  #Ale2 .financiera {
    bottom: 250px;
    right: 450px;
  }

  #Ale2 .tesoreria {
    bottom: 300px;
    right: 370px;
  }

  #Ale2 .salaprofe {
    bottom: 330px;
    right: 170px;
  }

  #Ale2 .vice {
    bottom: 170px;
    right: 450px;
  }

  #Ale2 .rectoria {
    bottom: 130px;
    right: 380px;
  }

  #Ale2 .admisiones {
    bottom: 140px;
    right: 280px;
  }

  #Ale2 .pastoral {
    bottom: 220px;
    right: 250px;
  }

  #Ale2 .pastoral:hover::before {
    content: "Pastoral Universitaria";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Ale3 .financiera2 {
    bottom: 260px;
    right: 410px;
  }

  #Ale3 .gh {
    bottom: 350px;
    right: 280px;
  }

  #Ale3 .sc1 {
    bottom: 300px;
    right: 280px;
  }

  #Ale3 .sc2 {
    bottom: 280px;
    right: 260px;
  }


  #Kabai3 .emprendimiento {
    bottom: 160px;
    left: 540px;
  }

  #Kabai3 .plataforma {
    bottom: 180px;
    left: 800px;
  }

  #Dabar3 .salonesd3 {
    bottom: 250px;
    right: 300px;
  }

  #Dabar3 .oval3 {
    bottom: 220px;
    right: 480px;
  }

  #Dabar3 .salamac {
    bottom: 260px;
    right: 200px;
  }

  #Biblioteca1 .bibliocafe {
    bottom: 340px;
    right: 470px;
  }

  #Biblioteca1 .infantil {
    bottom: 220px;
    right: 220px;
  }

  #Biblioteca1 .dinv {
    bottom: 280px;
    right: 490px;
  }

  #Biblioteca2 .prestamos {
    bottom: 210px;
    right: 470px;
  }

  #Biblioteca2 .emeroteca {
    bottom: 210px;
    right: 230px;
  }

  #Biblioteca4 .pinacoteca {
    bottom: 280px;
    right: 480px;
  }


  #Dabar2 .gradas {
    bottom: 370px;
    right: 500px;
  }

  #Dabar2 .salamultiple {
    bottom: 400px;
    right: 420px;
  }

  #Dabar2 .lab_fisica {
    bottom: 250px;
    right: 360px;
  }

  #Dabar2 .labii {
    bottom: 210px;
    right: 280px;
  }



  #Dabar2 .gradas:hover::before {
    content: "Teatro al aire libre";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .salamultiple:hover::before {
    content: "Sala múltiple";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .lab_fisica:hover::before {
    content: "Laboratorio de física";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .labii:hover::before {
    content: "Laboratorio de ingeniería industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .inter:hover::before {
    content: "Internacionalización";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .salonesk3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Biblioteca4 .pinacoteca:hover::before {
    content: "Pinacoteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca2 .prestamos:hover::before {
    content: "Circulación y préstamos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Biblioteca2 .emeroteca:hover::before {
    content: "Hemeroteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Biblioteca2 .emeroteca:hover::before {
    content: "Hemeroteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Biblioteca1 .bibliocafe:hover::before {
    content: "Bibliocafé";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca1 .infantil:hover::before {
    content: "Sala infantil";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca1 .dinv:hover::before {
    content: "Dirección de Investigación e Innovación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Dabar3 .salamac:hover::before {
    content: "Sala mac";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar3 .oval3:hover::before {
    content: "Oval 3";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar3 .salonesd3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .emprendimiento:hover::before {
    content: "Sala de emprendimiento";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .plataforma:hover::before {
    content: "Plataforma innovarte";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .financiera2:hover::before {
    content: " Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .gh:hover::before {
    content: "Gestión Tecnológica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale3 .sc1:hover::before {
    content: "Sala de sistemas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale3 .sc2:hover::before {
    content: "Sala de Sistemas 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .financiera:hover::before {
    content: "Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .tesoreria:hover::before {
    content: "Tesorería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .rectoria:hover::before {
    content: "Rectoría";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .vice:hover::before {
    content: "Vicerrectoría Académica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .salaprofe:hover::before {
    content: "Cubículos de profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .admisiones:hover::before {
    content: "Admisiones y Registro";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .salonesd2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .oval2:hover::before {
    content: "Oval 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .cubiculosdabar:hover::before {
    content: "Cubículos insonorizados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .centrocultura:hover::before {
    content: "Centro de Actividades Culturales";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .teatrino:hover::before {
    content: "Teatrino";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  .lista_kabai {
    right: 300px;
    font-weight: bold;
    text-align: start;
    background-color: white;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 13px;
  }

  #Kabai2 .salonesk2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .lab_neuro:hover::before {
    content: "Laboratorio de neurociencia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .lab_psi:hover::before {
    content: "Laboratorio de psicología";
    position: absolute;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .programa_DA:hover::before {
    content: "Decanatura de Facultad de Ciencias Básicas e Ingeniería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .baños:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .DCE:hover::before {
    content: "Decanatura de Facultad de Ciencias Económicas y Administrativas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .acuario:hover::before {
    content: "Oficinas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena2 .gym:hover::before {
    content: "Gimnasio";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena2 .salonesbuena2:hover::before {
    content: "Taller de diseño industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .prodilab:hover::before {
    content: "Prodilab";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .arquitectura:hover::before {
    content: "Taller de diseño industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .medio:hover::before {
    content: "Centro de medios";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .oval1:hover::before {
    content: "Oval 1";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .estudiante:hover::before {
    content: "Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .ciuc:hover::before {
    content: "Proyección Social y Centro de Idiomas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .comunicaciones:hover::before {
    content: "Coordinación de Comunicaciones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .psicologia:hover::before {
    content: "Dirección de Psicología";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Capsi .enfermeria:hover::before {
    content: "Enfermería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Capsi .capsi:hover::before {
    content: "CAPSI";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Capsi .cubicapsi {
    bottom: 200px;
    left: 630px;
  }

  #Capsi .cubicapsi:hover::before {
    content: "Cubículos CAPSI";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .direccionadm:hover::before {
    content: "Dirección Administrativa y Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Kabai1 .cafeteria:hover::before {
    content: "Cafetería azul";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .estudiantes:hover::before {
    content: "Entrada Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .patio:hover::before {
    content: "Patio interior";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .papeleria:hover::before {
    content: "Papelería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .mercadeo:hover::before {
    content: "Dirección de Programa de Mercadeo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .foto {
    bottom: 180px;
    right: 230px;
  }

  #Kabai1 .foto:hover::before {
    content: "Fotocopiadora";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .vrp2:hover::before {
    content: "Vicerrectoría Proyecto de Vida    ";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .salones:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .salvip {
    bottom: 150px;
    right: 270px;
  }

  #Kabai3 .salvip:hover::before {
    content: "Sala VIP";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .auditorio:hover::before {
    content: "Auditorio Dabar";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .vrp:hover::before {
    content: "Centro de Familia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .pb:hover::before {
    content: "Punto de Bolsa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .consejos:hover::before {
    content: "Sala de consejos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cie:hover::before {
    content: "Centro de Innovación Educativa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cubiculos:hover::before {
    content: "Cubículos de estudio";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .laboratorio:hover::before {
    content: "Laboratorio de visualización avanzada";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .ciesalones:hover::before {
    content: "Aula virtual y módulos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .cafeteriaroja:hover::before {
    content: "Cafetería roja";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .banco:hover::before {
    content: "Banco AV Villas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .tiendau:hover::before {
    content: "Tienda universitaria";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .gmercadeo:hover::before {
    content: "Gestión de Mercadeo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Humanitas1 .gestionh:hover::before {
    content: "Gestión Humana";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .practicas:hover::before {
    content: "Prácticas y Graduados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .capilla:hover::before {
    content: "Capilla: San José";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .hsalones:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .estudiante:hover::before {
    content: "Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }
}

@media (min-width: 200px) and (max-width: 600px) {
  .modal-body {
    flex: none !important;
    flex-direction: column;
    width: 100%;
  }

  .iconosPisos {
    position: absolute;
    bottom: -80px;
    left: 20px;
    gap: 10px;
    width: 100%;
  }

  .bibliotecapiso1,
  .bibliotecapiso2,
  .bibliotecapiso3,
  .bibliotecapiso4 {
    width: 300px;
    height: 200px !important;
    margin-top: 40px;
  }

  .IMGkabai3,
  .Daba1Img,
  .posgrados2,
  .posgrados3img,
  .Medios2 {
    width: 350px;
    height: 250px !important;
    margin-top: 40px;
  }

  .wrapperCambiante {
    position: relative;
    height: fit-content;
    margin-right: auto !important;
  }

  .modal-content img {
    height: 300px;
  }

  .capsiImagen,
  .kabai1Imagen,
  .imagenKabai2 {
    width: 350px !important;
  }

  .imgk .lista_kabai {
    left: 0;
    font-weight: bold;
    text-align: start;
    background-color: white;
    padding: 10px 30px;
    border-radius: 20px;
    font-size: 9px;
  }

  #Kabai1 i,
  #Kabai2 i,
  #Dabar1 i,
  #Dabar3 i,
  #Dabar2 i,
  #Kabai3 i,
  #Ale1 i,
  #Ale2 i,
  #Ale3 i,
  #Humanitas1 i,
  #Humanitas2 i,
  #Capsi i,
  #Buena1 i,
  #Biblioteca1 i,
  #Biblioteca2 i,
  #Biblioteca3 i,
  #Biblioteca4 i,
  #Posgrados1 i,
  #Posgrados2 i,
  #Posgrados3 i,
  #Medios2 i,
  #Buena2 i {
    font-size: 20px;
    position: absolute;
    color: brown;
    text-shadow: 0 0 5px white;
  }

  #Ale3 .financiera2 {
    bottom: 180px;
    right: 260px;
  }

  #Ale3 .gh {
    bottom: 240px;
    right: 160px;
  }

  .lista_kabai {
    left: 5px;
    font-weight: bold;
    text-align: start;
    background-color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 9.5px;
  }


  #Posgrados3 .coorpc {
    bottom: 270px;
    left: 180px;
  }

  #Posgrados3 .coorpc:hover::before {
    content: "Coordinación de Especialización en Psicología Clínica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorcgh {
    bottom: 310px;
    left: 220px;
  }

  #Posgrados3 .coorcgh:hover::before {
    content: "Coordinación de Especialización en Gestión Humana de las Organizaciones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorgpi {
    bottom: 150px;
    left: 260px;
  }

  #Posgrados3 .coorgpi:hover::before {
    content: "Coordinación de Especialización en Gestión de Proyectos de Innovación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Posgrados3 .cooredumatica {
    bottom: 180px;
    left: 210px;
  }

  #Posgrados3 .cooredumatica:hover::before {
    content: "Coordinación de Especialización en Edumática";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorpsisocial {
    bottom: 160px;
    left: 180px;
  }

  #Posgrados3 .coorpsisocial:hover::before {
    content: "Coordinación de Especialización en Psicología Social Comunitaria y Acción Psicosocial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados3 .coorpc {
    bottom: 100px;
    right: 190px;
  }


  #Posgrados3 .coorcgh {
    bottom: 120px;
    right: 140px;
  }

  #Posgrados3 .coorpc:hover::before {
    content: "Coordinación de Psicología Clínica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados3 .coorcgh:hover::before {
    content: "Coordinación de Comunicación y Gestión Humana";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .bañoshumanitas {
    bottom: 140px;
    left: 150px;
  }

  #Humanitas1 .bañoshumanitas:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .bañoskabai1 {
    bottom: 150px;
    left: 250px;
  }

  #Kabai1 .bañoskabai1:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .bañosale {
    bottom: 190px;
    left: 100px;
  }

  #Ale1 .bañosale:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .sc1 {
    bottom: 200px;
    right: 180px;
  }

  #Ale3 .sc2 {
    bottom: 160px;
    right: 150px;
  }

  #Ale3 .fcbi {
    bottom: 120px;
    right: 170px;
  }



  #Humanitas1 .salones2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .bigdata {
    bottom: 70px;
    left: 110px;
  }

  #Kabai3 .bigdata:hover::before {
    content: "Laboratorio de big data";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas1 .salones2 {
    bottom: 150px;
    right: 100px;
  }

  #Humanitas1 .salones2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .dyp {
    bottom: 100px;
    right: 130px;
  }

  #Humanitas1 .dyp:hover::before {
    content: "Dirección de Planeación y Calidad";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 15px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .almacen {
    bottom: 115px;
    right: 200px;
  }

  #Humanitas1 .almacen:hover::before {
    content: "Almacén";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;

    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .patio {
    bottom: 150px;
    left: 200px;
  }


  #Humanitas1 .estudiantes {
    bottom: 110px;
    left: 260px;
  }


  #Humanitas1 .patio:hover::before {
    content: "Patio interior";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas1 .estudiantes:hover::before {
    content: "Entrada Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena2 .salonesbuena2 {
    bottom: 130px;
    right: 120px;
  }

  #Buena2 .gym {
    bottom: 200px;
    right: 220px;
  }

  #Buena2 .gym:hover::before {
    content: "Gimnasio";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena2 .salonesbuena2:hover::before {
    content: "Taller de diseño industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Posgrados2 .secrehuma {
    bottom: 150px;
    right: 180px;
  }

  #Posgrados2 .direccionhuma {
    bottom: 170px;
    right: 130px;
  }

  #Posgrados2 .posgradopegaogia {
    bottom: 150px;
    right: 80px;
  }

  #Posgrados2 .profesionalpeda {
    bottom: 110px;
    right: 110px;
  }

  #Posgrados2 .direccionlic {
    bottom: 90px;
    right: 140px;
  }

  #Posgrados2 .profesionalpeda:hover::before {
    content: "Profesional de Posgrados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .posgradopegaogia:hover::before {
    content: "Posgrados Pedagogía y Desarrollo Humano";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .direccionhuma:hover::before {
    content: "Dirección Humanidades";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .secrehuma:hover::before {
    content: "Secretaría Humanidades y LER";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Posgrados2 .direccionlic:hover::before {
    content: "Dirección de Licenciatura de Educación Religiosa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .fcbi:hover::before {
    content: "Facultad de Ciencias Básicas e Ingeniería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Ale3 .financiera2:hover::before {
    content: "Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale3 .gh:hover::before {
    content: "Gestión Tecnológica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale3 .sc1:hover::before {
    content: "Sala de sistemas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale3 .sc2:hover::before {
    content: "Sala de Sistemas 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai1 .cafeteria {
    bottom: 80px;
    right: 110px;
  }

  #Kabai1 .papeleria {
    bottom: 80px;
    right: 50px;
  }

  #Posgrados1 .piso1posgrados {
    bottom: 160px;
    right: 180px;
  }

  #Posgrados1 .piso1posgrados:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Kabai1 .vrp2 {
    bottom: 240px;
    right: 240px;
  }

  #Kabai3 .aulav1 {
    bottom: 120px;
    right: 230px;
  }

  #Kabai3 .aulav1:hover::before {
    content: "Aula virtual 1";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .salvip {
    bottom: 90px;
    right: 170px;
  }

  #Kabai3 .salvip:hover::before {
    content: "Sala VIP";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai1 .mercadeo {
    bottom: 140px;
    right: 160px;
  }

  #Kabai1 .salones {
    bottom: 170px;
    right: 240px;
  }

  #Kabai1 .foto {
    bottom: 120px;
    right: 130px;
  }

  #Posgrados2 .piso2posgrados {
    bottom: 140px;
    right: 140px;
  }

  #Medios2 .Cabinas {
    bottom: 130px;
    right: 60px;
  }


  #Medios2 .radio {
    bottom: 190px;
    right: 130px;
  }

  #Medios2 .salonmedio2 {
    bottom: 160px;
    right: 260px;
  }

  #Medios2 .edicionmedio2 {
    bottom: 120px;
    right: 230px;
  }

  #Medios2 .Cabinas:hover::before {
    content: "Cabina";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Medios2 .radio:hover::before {
    content: "Radio UCP";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);

  }

  #Medios2 .salonmedio2:hover::before {
    content: "Salón";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);

  }

  #Medios2 .edicionmedio2:hover::before {
    content: "Salones de Edición";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Posgrados2 .piso2posgrados:hover::before {
    content: "Dep. Humanidades y Programa LER";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Dabar1 .auditorio {
    bottom: 180px;
    right: 200px;
  }

  #Buena1 .piso2 {
    bottom: 140px;
    right: 110px;
  }

  #Biblioteca3 .salag {
    bottom: 150px;
    right: 250px;
  }

  #Biblioteca3 .salap {
    bottom: 140px;
    right: 160px;
  }

  #Biblioteca3 .salagrupal {
    bottom: 110px;
    right: 110px;
  }

  #Biblioteca3 .cubiculosb {
    bottom: 90px;
    right: 250px;
  }




  #Biblioteca3 .salag:hover::before {
    content: "Sala general";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .salap:hover::before {
    content: "Sala de profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .salagrupal:hover::before {
    content: "Sala grupal";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca3 .cubiculosb:hover::before {
    content: "Cubículos profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena1 .piso2:hover::before {
    content: "Piso 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
    transition: 0.1s;
    cursor: pointer;

  }

  #Dabar1 .vrp {
    bottom: 150px;
    right: 130px;
  }

  #Dabar1 .consejos {
    bottom: 140px;
    right: 80px;
  }


  #Biblioteca1 .referencia {
    bottom: 80px;
    right: 210px;
  }

  #Biblioteca1 .referencia:hover::before {
    content: "Referencia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .pb {
    bottom: 60px;
    right: 80px;
  }



  #Kabai3 .cie {
    bottom: 120px;
    left: 160px;
  }

  #Kabai3 .cubiculos {
    bottom: 105px;
    left: 170px;
  }

  #Kabai3 .laboratorio {
    bottom: 90px;
    left: 130px;
  }

  #Ale1 .cafeteriaroja {
    bottom: 150px;
    left: 200px;
  }

  #Kabai3 .ciesalones {
    bottom: 130px;
    left: 120px;
  }

  #Ale1 .banco {
    bottom: 180px;
    left: 70px;
  }

  #Ale1 .gmercadeo {
    bottom: 230px;
    left: 150px;
  }

  #Ale1 .tiendau {
    bottom: 190px;
    left: 190px;
  }

  #Capsi .capsi {
    bottom: 110px;
    left: 260px;
  }

  #Capsi .enfermeria {
    bottom: 130px;
    left: 100px;
  }

  #Capsi .cubicapsi {
    bottom: 130px;
    left: 140px;
  }

  #Capsi .cubicapsi:hover::before {
    content: "Cubículos CAPSI";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Humanitas1 .gestionh {
    bottom: 180px;
    right: 200px;
  }

  #Humanitas1 .practicas {
    bottom: 200px;
    right: 300px;
  }

  #Humanitas1 .capilla {
    bottom: 120px;
    right: 85px;
  }

  #Humanitas1 .hsalones {
    bottom: 170px;
    left: 90px;
  }

  #Humanitas2 .estudiante {
    bottom: 110px;
    left: 250px;
  }

  #Humanitas2 .ciuc {
    bottom: 210px;
    left: 130px;
  }

  #Humanitas2 .comunicaciones {
    bottom: 170px;
    left: 240px;
  }

  #Humanitas2 .psicologia {
    bottom: 120px;
    left: 180px;
  }

  #Humanitas2 .direccionadm {
    bottom: 170px;
    left: 110px;
  }

  #Buena1 .prodilab {
    bottom: 240px;
    right: 210px;
  }

  #Buena1 .arquitectura {
    bottom: 180px;
    right: 160px;
  }

  #Buena1 .medio {
    bottom: 140px;
    right: 140px;
  }

  #Buena1 .oval1 {
    bottom: 80px;
    right: 200px;
  }

  #Kabai2 .salonesk2 {
    bottom: 180px;
    right: 240px;
  }

  #Kabai2 .lab_neuro {
    bottom: 130px;
    right: 110px;
  }

  #Kabai2 .lab_psi {
    bottom: 100px;
    right: 70px;
  }

  #Kabai2 .programa_DA {
    bottom: 145px;
    right: 130px;
  }

  #Kabai2 .DCE {
    bottom: 85px;
    right: 100px;
  }

  #Kabai2 .acuario {
    bottom: 85px;
    right: 130px;
  }

  #Kabai2 .baños {
    bottom: 160px;
    right: 120px;
  }

  #Dabar2 .teatrino {
    bottom: 190px;
    right: 260px;
  }

  #Dabar2 .centrocultura {
    bottom: 210px;
    right: 240px;
  }

  #Dabar2 .cubiculosdabar {
    bottom: 215px;
    right: 280px;
  }

  #Dabar2 .oval2 {
    bottom: 80px;
    right: 140px;
  }

  #Dabar2 .salonesd2 {
    bottom: 150px;
    right: 150px;
  }

  #Ale2 .financiera {
    bottom: 160px;
    right: 280px;
  }

  #Ale2 .tesoreria {
    bottom: 210px;
    right: 200px;
  }

  #Ale2 .salaprofe {
    bottom: 210px;
    right: 120px;
  }

  #Ale2 .vice {
    bottom: 120px;
    right: 280px;
  }

  #Ale2 .rectoria {
    bottom: 90px;
    right: 230px;
  }

  #Ale2 .admisiones {
    bottom: 110px;
    right: 190px;
  }

  #Ale2 .pastoral {
    bottom: 150px;
    right: 170px;
  }

  #Ale2 .pastoral:hover::before {
    content: "Pastoral Universitaria";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
    transform: scale(1.1);
  }

  #Kabai3 .emprendimiento {
    bottom: 100px;
    left: 70px;
  }

  #Kabai3 .plataforma {
    bottom: 110px;
    left: 230px;
  }

  #Kabai3 .salonesk3 {
    bottom: 200px;
    left: 250px;
  }

  #Dabar3 .salonesd3 {
    bottom: 150px;
    left: 170px;
  }

  #Dabar3 .oval3 {
    bottom: 150px;
    left: 80px;
  }

  #Dabar3 .salamac {
    bottom: 160px;
    left: 210px;
  }

  #Biblioteca1 .bibliocafe {
    bottom: 160px;
    right: 260px;
  }

  #Biblioteca1 .infantil {
    bottom: 100px;
    right: 130px;
  }

  #Biblioteca1 .dinv {
    bottom: 130px;
    right: 270px;
  }

  #Biblioteca2 .prestamos {
    bottom: 100px;
    right: 250px;
  }

  #Biblioteca2 .emeroteca {
    bottom: 80px;
    right: 140px;
  }

  #Biblioteca4 .pinacoteca {
    bottom: 120px;
    right: 220px;
  }

  #Kabai2 .inter {
    bottom: 70px;
    right: 100px;
  }


  #Dabar1 .salonesd1 {
    bottom: 100px;
    right: 110px;
  }

  #Dabar2 .gradas {
    bottom: 235px;
    right: 310px;
  }

  #Dabar2 .salamultiple {
    bottom: 230px;
    right: 220px;
  }

  #Dabar2 .lab_fisica {
    bottom: 170px;
    right: 220px;
  }

  #Dabar2 .labii {
    bottom: 140px;
    right: 180px;
  }

  #Dabar2 .ingsis {
    bottom: 155px;
    left: 175px;
  }

  #Dabar2 .ingsis:hover::before {
    content: "Dirección de Ingeniería en Sistemas y Telecomunicaciones, Dirección del Departamento de Ciencias Básicas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar2 .gradas:hover::before {
    content: "Teatro al aire libre";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .salamultiple:hover::before {
    content: "Sala múltiple";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .lab_fisica:hover::before {
    content: "Laboratorio de física";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .labii:hover::before {
    content: "Laboratorio de ingeniería industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar1 .salonesd1:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .inter:hover::before {
    content: "Internacionalización";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca4 .pinacoteca:hover::before {
    content: "Pinacoteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Kabai1 .foto:hover::before {
    content: "Fotocopiadora";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca2 .prestamos:hover::before {
    content: "Circulación y préstamos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca2 .emeroteca:hover::before {
    content: "Hemeroteca";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Biblioteca1 .bibliocafe:hover::before {
    content: "Bibliocafé";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca1 .infantil:hover::before {
    content: "Sala infantil";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Biblioteca1 .dinv:hover::before {
    content: "Dirección de Investigación e Innovación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Dabar3 .salamac:hover::before {
    content: "Sala mac";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar3 .oval3:hover::before {
    content: "Oval 3";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Dabar3 .salonesd3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }




  #Kabai3 .emprendimiento:hover::before {
    content: "Sala de emprendimiento";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai3 .salonesk3:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .plataforma:hover::before {
    content: "Plataforma innovarte";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .financiera:hover::before {
    content: "Gestión Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .tesoreria:hover::before {
    content: "Tesorería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .archivo {
    bottom: 200px;
    left: 70px;
  }

  #Humanitas2 .archivo:hover::before {
    content: "Archivo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }





  #Ale2 .rectoria:hover::before {
    content: "Rectoría";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .vice:hover::before {
    content: "Vicerrectoría Académica";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Ale2 .salaprofe:hover::before {
    content: "Cubículos de profesores";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale2 .admisiones:hover::before {
    content: "Admisiones y Registro";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Dabar2 .salonesd2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .oval2:hover::before {
    content: "Oval 2";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .cubiculosdabar:hover::before {
    content: "Cubículos insonorizados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .centrocultura:hover::before {
    content: "Centro de Actividades Culturales";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar2 .teatrino:hover::before {
    content: "Teatrino";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Kabai2 .salonesk2:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .lab_neuro:hover::before {
    content: "Laboratorio de neurociencia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .lab_psi:hover::before {
    content: "Laboratorio de psicología";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .programa_DA:hover::before {
    content: "Decanatura de Facultad de Ciencias Básicas e Ingeniería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .baños:hover::before {
    content: "Baños";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .DCE:hover::before {
    content: "Decanatura de Facultad de Ciencias Económicas y Administrativas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai2 .acuario:hover::before {
    content: "Oficinas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Buena1 .prodilab:hover::before {
    content: "Prodilab";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .arquitectura:hover::before {
    content: "Taller de diseño industrial";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .medio:hover::before {
    content: "Centro de medios";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Buena1 .oval1:hover::before {
    content: "Oval 1";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Kabai1 .cafeteria:hover::before {
    content: "Cafetería azul";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .papeleria:hover::before {
    content: "Papelería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .salones:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .mercadeo:hover::before {
    content: "Dirección de Programa de Mercadeo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai1 .vrp2:hover::before {
    content: "Vicerrectoría Proyecto de Vida";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .auditorio:hover::before {
    content: "Auditorio Dabar";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .vrp:hover::before {
    content: "Centro de Familia";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .pb:hover::before {
    content: "Punto de Bolsa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Dabar1 .consejos:hover::before {
    content: "Sala de consejos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cie:hover::before {
    content: "Centro de Innovación Educativa";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .cubiculos:hover::before {
    content: "Cubículos de estudio";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .laboratorio:hover::before {
    content: "Laboratorio de visualización avanzada";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Kabai3 .ciesalones:hover::before {
    content: "Aula virtual y módulos";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Capsi .capsi:hover::before {
    content: "CAPSI";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Capsi .enfermeria:hover::before {
    content: "Enfemería";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .cafeteriaroja:hover::before {
    content: "Cafetería roja";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .banco:hover::before {
    content: "Banco AV Villas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .gmercadeo:hover::before {
    content: "Gestión de Mercadeo";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Ale1 .tiendau:hover::before {
    content: "Tienda universitaria";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }



  #Humanitas1 .gestionh:hover::before {
    content: "Gestión Humana";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .practicas:hover::before {
    content: "Prácticas y Graduados";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .capilla:hover::before {
    content: "Capilla: San José";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas1 .hsalones:hover::before {
    content: "Salones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 12px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .estudiante:hover::before {
    content: "Sala del Estudiante";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .comunicaciones:hover::before {
    content: "Coordinación de Comunicaciones";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .psicologia:hover::before {
    content: "Dirección de Psicología";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas2 .hfche {
    bottom: 110px;
    left: 160px;
  }


  #Humanitas2 .hfche:hover::before {
    content: "Facultad de Ciencias Humanas, Sociales y de la Educación";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }


  #Humanitas2 .direccionadm:hover::before {
    content: "Dirección Administrativa y Financiera";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  #Humanitas2 .ciuc:hover::before {
    content: "Proyección Social y Centro de Idiomas";
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    font-size: 15px;
    background-color: brown;
    position: relative;
    z-index: 100;
    padding: 5px;
    border-radius: 25px;
    font-family: "Poppins", sans-serif !important;
  }

  .pisosBiblioteca {
    display: grid;
    grid-template-columns: 100px 100px !important;
    bottom: 70px !important;
    left: 70px !important;
    height: 0px;
  }

  .modal-footer.bibliotecafooter button {
    margin-top: 80px !important
  }

}

.modalGenially {
  width: 100vw !important;
}


.ImagenCambiante {
  border-radius: 20px;

}

.wrapperCambiante {
  position: relative;
  height: fit-content;
  margin-right: 60px;
}

.wrapperCambiante::before {
  position: absolute;
  content: "";
  border-radius: 25px;
  border: white 1px solid;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3px -3px -1px -3px;
  z-index: 1;
  opacity: 0.5;
}



.modal-body {
  display: flex;
  align-items: center;

}

.wrapperCambiante .NombreCambiante {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -50px;

}

.wrapperCambiante h3 {
  position: relative;
  background-color: #AE66AA;
  color: white;
  font-weight: bold;
  font-size: 17px;
  padding: 10px 30px;
  border-radius: 40px;
  z-index: 99;
  box-shadow: 0 0 5px 1px black;
  width: 90%;
}


.NombreCambianteSolito h3 {
  width: fit-content !important;
}


.wrapperCambiante h3::before {
  position: absolute;
  content: "";
  border-radius: 40px;
  border: white 1px solid;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3px -3px -1px -3px;
  z-index: 1;
  opacity: 0.5;
}

.modalrojo img {
  height: 400px;
}

@media (min-width: 300px) and (max-width: 1000px) {

  .modalrojo img {
    height: 300px;
  }
}



.iconosPisos {
  font-size: 20px;
  color: white;
  height: auto;
  display: grid;
  grid-template-columns: 100px 100px 100px;
}

.iconosPisos div {

  background-color: rgb(255, 255, 255, 0.2);
  margin: 5px;
  border-radius: 10px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.iconosPisos div:nth-child(4n) {
  width: 320px;
}


.pisoactivo {
  background-color: rgb(0, 0, 0, 0.4) !important;

}

.pisosBiblioteca {
  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  bottom: 100px;
  left: -20px;
}


.pisosBiblioteca div:nth-child(4n) {
  width: 100px;
}