.formularios th {
  text-align: start;
  font-weight: lighter  !important;
}

.formularios input {
  transform: scale(1.7);
  cursor: pointer;
}

.formularios .numberHeader {
  text-align: center;
  width: 60px;
}

.formularios td {
  padding: 15px 0px;
}

.container_cartas {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container_cartas .card {
  margin: 20px;
}

.containerGrafica {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: none;
  width: 100%;
}

.formularios p {
  text-align: start;
  font-weight: lighter !important;
  margin: 0;
    color: #4c545b;
    margin-bottom: 20px;
}

.formularios h3{
    text-align: start;
  
    margin-top: 50px;
    font-weight: bold;
    font-size: 3rem;
}



.formularios thead{
    background-color: #212529;
    color: white;
    height: 50px !important;
}

.formularios button{
    padding: 10px 80px;
    border: none;
    border-radius: 30px;
    background-color: #212529;
    color: white;
}

.formularios button:hover{

    background-color: #2d2f31;
    color: white;
    transform: scale(1.1);
}


button:disabled{
  cursor: not-allowed;
}

input:disabled{
  cursor: not-allowed;
}

button:disabled:hover{
  transform: scale(1);
}

.wrapperResultados{
  display: flex;
  justify-content: center;
}

@media (min-width: 300px) and (max-width: 520px) {

  .wrapperResultados{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}



.WrapperModal{
  background: white;
  color: black;
  height: 100%;
  text-align: justify;
  overflow: scroll;
  padding: 20px;
}

.WrapperModal h3{
  font-weight: bold;
}

.WrapperModal p{
  margin-bottom: 10px;
 
}

.WrapperModal div{
  margin: 20px;
  border-bottom: solid 1px rgb(0, 0, 0, 0.2);
}