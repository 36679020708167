@media (min-width: 1001px) {

  .imagen_mapa h5 {
    position: absolute;
    right: 120px;
    bottom: 76px;
    color: white;
    font-weight: 700;
    font-size: 3rem;
    text-shadow: 1px 1px 5px black;
    text-align: start;
    height: 100%;
    display: flex;
    align-items: flex-end;
    user-select: none;

  }

  .imagen_mapa h5#segundotitulo {
    position: absolute;
    right: 120px;
    bottom: 20px !important;
    color: white;
    font-weight: 700;
    font-size: 4rem;
    text-shadow: 1px 1px 5px black;
    text-align: start;
    height: 100%;
    display: flex;
    align-items: flex-end;
    user-select: none;
  }

  .container_mapa {
    display: flex;
    grid-template-columns: 70% 30%;
    height: auto;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }

  .imagen_mapa {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px;
    z-index: 2;
  }

  .imagen_mapa img {
    width: 800px;
    overflow: hidden;
    margin-right: 350px;
  }

  .tabla_sitios::-webkit-scrollbar {
    display: none;
  }

  .tabla_contenido {
    height: auto;
    overflow: scroll;
    display: flex;
    padding-bottom: 10px;
  }

  .tabla_sitios {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10px;
    padding-bottom: 10px;
  }

  .tabla_sitios table {
    width: 185px;

    margin: 10px !important;

  }

  .tabla_sitios thead {
    position: relative;
    background-color: #d36169;
    border: 1px solid rgb(179, 173, 173);
    color: white;
  }

  .tabla_sitios thead th {
    padding: 10px;
  }

  .tabla_sitios input {
    width: 100%;
  }

  .tabla_sitios thead th span {
    position: absolute;
    right: 0;
    font-size: 20px;
    margin-right: 10px;
    width: 20px;
    cursor: pointer;
    color: aliceblue;
  }

  .tabla_sitios th {
    text-align: flex-start;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
  }

  .tabla_sitios thead::before {
    position: absolute;
    content: "";
    border-radius: 10px;
    border: white 1px solid;
    left: 0;
    top: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -3px;
    opacity: 0.5;
  }

  .places_icons #geo_1 {
    position: absolute;
    bottom: -80px;
    left: -300px;
  }

  .buenanueva p {
    position: absolute;
    bottom: -100px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -270px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }


  .cancha p {
    position: absolute;
    bottom: -120px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -100px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .entradaprincipal p {
    position: absolute;
    top: -130px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -210px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .parqueadero p {
    position: absolute;
    top: -180px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -240px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .rioconsota p {
    position: absolute;
    top: -110px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: 10px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .parqueaderomotos p {
    position: absolute;
    top: -230px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -320px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .parqueaderoadm p {
    position: absolute;
    top: 130px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -540px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }



  .entradanormandia p {
    position: absolute;
    bottom: -210px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -135px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .plazoleta p {
    position: absolute;
    top: -130px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -220px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .parquebanderas p {
    position: absolute;
    top: -100px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -500px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .container_btn {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;
  }

  .buenanueva .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }

  .container_btn button {
    background-color: rgb(255, 255, 255, 0.2);
    width: max-content;
    font-size: 15px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 5px;
  }

  .places_icons #geo_2 {
    position: absolute;
    bottom: -40px;
    left: -300px;
  }

  .dabar p {
    position: absolute;
    bottom: -70px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -240px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .dabar .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }


  .places_icons #geo_1:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_3 {
    position: absolute;
    bottom: -30px;
    left: -260px;
  }

  .kabai p {
    position: absolute;
    bottom: -40px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -220px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .kabai .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }

  .places_icons #geo_3:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .aletheian p {
    position: absolute;
    bottom: -50px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -220px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .aletheian .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }

  .places_icons #geo_4 {
    position: absolute;
    bottom: 15px;
    left: -250px;
  }

  .places_icons #geo_4:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_5 {
    position: absolute;
    bottom: 55px;
    left: -290px;
  }

  .biblioteca p {
    position: absolute;
    bottom: 70px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -420px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .biblioteca .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }

  .places_icons #geo_5:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_6 {
    position: absolute;
    bottom: 20px;
    left: -140px;
  }

  .humanitas p {
    position: absolute;
    bottom: -10px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -100px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;
  }

  .humanitas .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }

  .places_icons #geo_6:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_7 {
    position: absolute;
    bottom: 0px;
    left: -170px;
  }

  .posgrados p {
    position: absolute;
    bottom: -40px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -150px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .posgrados .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -70px;
    left: -400px;
  }

  .places_icons #geo_7:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_8 {
    position: absolute;
    bottom: -110px;
    left: -150px;
    color: #5E2129 !important;
  }

  .places_icons #geo_8:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_9 {
    position: absolute;
    bottom: 30px;
    left: -200px;
    color: #5E2129 !important;
  }

  .places_icons #geo_9:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_10 {
    position: absolute;
    bottom: 60px;
    left: -250px;
    color: #5E2129 !important;
  }

  .places_icons #geo_10:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_11 {
    position: absolute;
    bottom: -200px;
    left: -195px;
    color: #5E2129 !important;
  }

  .places_icons #geo_11:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .mapa222 {
    position: absolute;
    height: 400px;
    width: 400px !important;
  }

  .places_icons {
    position: absolute;
    font-size: 2em;
    color: purple;
    text-shadow: 0 0 4px white;
  }

  .places_icons #geo_12 {
    position: absolute;
    top: -170px;
    left: -280px;
    color: #5E2129 !important;
  }

  .places_icons #geo_12:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_13 {
    position: absolute;
    top: -220px;
    left: -360px;
    color: #5E2129 !important;

  }

  .places_icons #geo_13:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_14 {
    position: absolute;
    bottom: 50px;
    left: -40px;
    color: #5E2129 !important;

  }

  .places_icons #geo_14:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_15 {
    position: absolute;
    bottom: -150px;
    left: -320px;
    color: #5E2129 !important;
  }

  .places_icons #geo_16 {
    position: absolute;
    bottom: 20px;
    left: -290px;
    color: #5E2129 !important;
    font-size: 25px !important;
  }

  .places_icons #geo_15:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }


  .places_icons #geo_16:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }


}

@media (min-width: 200px) and (max-width: 650px) {
  .imagen_mapa h5 {
    color: white;
    font-weight: 700;
    font-size: 1rem;
    text-shadow: 1px 1px 5px black;
    text-align: start;
    height: 100%;
    display: flex;
    align-items: flex-end;
    user-select: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  .contenedorLetras {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 10px;

  }

  .imagen_mapa h5#segundotitulo {
    color: white;
    font-weight: 700;
    font-size: 1.34rem;
    text-shadow: 1px 1px 5px black;
    text-align: start;
    height: 100%;
    display: flex;
    align-items: flex-end;
    user-select: none;
  }
}

@media (min-width: 650px) and (max-width: 1000px) {
  .imagen_mapa h5 {
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    text-shadow: 1px 1px 5px black;
    text-align: start;
    height: 100%;
    display: flex;
    align-items: flex-end;
    user-select: none;
    margin: 0 !important;
    padding: 0 !important;
  }

  .contenedorLetras {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: 20px;

  }

  .imagen_mapa h5#segundotitulo {
    color: white;
    font-weight: 700;
    font-size: 2rem;
    text-shadow: 1px 1px 5px black;
    text-align: start;
    height: 100%;
    display: flex;
    align-items: flex-end;
    user-select: none;
  }
}

@media (min-width: 300px) and (max-width: 1000px) {
  .container_mapa {
    height: auto;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
  }




  .parquebanderas p {
    position: absolute;
    top: -150px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -20px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .imagen_mapa {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    position: relative;
  }

  .imagen_mapa img {
    width: 500px;
    overflow: hidden;
  }

  .tabla_sitios::-webkit-scrollbar {
    display: none;
  }

  .tabla_sitios thead:before {
    align-items: center;
    border: 1px solid #fff;
    border-radius: 10px;
    content: "";
    display: flex;
    height: -webkit-fill-available;
    justify-content: center;
    left: 0;
    margin: -3px;
    opacity: .5;
    position: absolute;
    top: 0;
    width: -webkit-fill-available;
  }

  .tabla_sitios {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    height: auto;
    overflow: scroll;
    z-index: 1;
    justify-content: flex-end;
  }

  .tabla_sitios table {
    width: 20px;
    overflow: scroll;
  }

  .tabla_sitios thead {
    position: relative;
    background-color: #d36169;
    border: 1px solid black;
    color: white;
  }

  .tabla_sitios thead th {
    padding: 10px;
  }

  .tabla_sitios input {
    padding: 10px;
    width: 80%;
    margin: 10px;
  }

  .tabla_sitios thead th span {
    position: absolute;
    right: 0;
    font-size: 20px;

    margin-right: 10px;
    width: 20px;
    cursor: pointer;
    color: aliceblue;
  }

  .tabla_sitios th {
    text-align: flex;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    z-index: 10;

  }

  .places_icons #geo_1 {
    position: absolute;
    bottom: -50px;
    left: -75px;
  }

  .buenanueva p {
    position: absolute;
    bottom: -100px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -90px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .container_btn {
    display: flex;
    flex-direction: column;
  }

  .buenanueva .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -40px;
    left: -170px;

  }

  .container_btn button {
    background-color: rgb(255, 255, 255, 0.2);
    width: max-content;
    font-size: 14px;
    padding: 5px;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    margin-top: 5px;
  }

  .places_icons #geo_2 {
    position: absolute;
    bottom: -25px;
    left: -90px;
  }

  .dabar p {
    position: absolute;
    bottom: -40px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -55px;
    font-size: 12px;
    visibility: hidden;
  }

  .dabar .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -60px;
    left: -170px;
  }

  .kabai p {
    position: absolute;
    bottom: -40px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -40px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .kabai .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -60px;
    left: -170px;
  }

  .places_icons #geo_2:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_3 {
    position: absolute;
    bottom: -10px;
    left: -70px;
  }

  .aletheian p {
    position: absolute;
    bottom: 30px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -40px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .aletheian .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -60px;
    left: -170px;
  }

  .places_icons #geo_3:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_4 {
    position: absolute;
    bottom: 10px;
    left: -50px;
  }

  .parqueadero p {
    position: absolute;
    top: -140px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -40px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .rioconsota p {
    position: absolute;
    top: -60px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: 80px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .parqueaderomotos p {
    position: absolute;
    top: -105px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -190px;
    font-size: 12px;
    visibility: hidden;
    text-shadow: none;
  }

  .parqueaderoadm p {
    position: absolute;
    top: 120px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -190px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;
  }

  .places_icons #geo_4:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_5 {
    position: absolute;
    bottom: 30px;
    left: -80px;
  }

  .biblioteca p {
    position: absolute;
    bottom: 30px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -30px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .biblioteca .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -60px;
    left: -170px;
  }

  .places_icons #geo_5:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_6 {
    position: absolute;
    bottom: 10px;
    left: 35px;
  }

  .humanitas p {
    position: absolute;
    bottom: -10px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: 70px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .humanitas .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -60px;
    left: -170px;
  }

  .places_icons #geo_6:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_7 {
    position: absolute;
    bottom: 0px;
    left: 10px;
  }

  .posgrados p {
    position: absolute;
    bottom: -50px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: 10px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .posgrados .container_btn {
    visibility: hidden;
    position: absolute;
    bottom: -60px;
    left: -170px;
  }

  .places_icons #geo_7:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .tabla_contenido {
    gap: 10px;
  }

  .mapa222 {
    position: absolute;
    height: 225px;
    width: 300px !important;
  }

  .places_icons {
    position: absolute;
    font-size: 1.5em;
    color: purple;
    text-shadow: 0 0 4px white;
  }

  .places_icons #geo_8 {
    position: absolute;
    bottom: -60px;
    left: 10px;
    color: #5E2129 !important;
  }

  .places_icons #geo_11 {
    position: absolute;
    bottom: -110px;
    left: -10px;
    color: #5E2129 !important;
  }

  .places_icons #geo_11:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_8:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_9 {
    position: absolute;
    bottom: 10px;
    left: -20px;
    color: #5E2129 !important;
  }

  .places_icons #geo_9:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_10 {
    position: absolute;
    bottom: 40px;
    left: -60px;
    color: #5E2129 !important;
  }

  .places_icons #geo_10:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .cancha p {
    position: absolute;
    bottom: -80px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: 50px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .entradaprincipal p {
    position: absolute;
    top: -100px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -30px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .entradanormandia p {
    position: absolute;
    bottom: -110px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: 15px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;
  }

  .plazoleta p {
    position: absolute;
    top: -100px;
    width: max-content;
    background-color: rgb(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 5px;
    left: -30px;
    font-size: 15px;
    visibility: hidden;
    text-shadow: none;

  }

  .tabla_contenido {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .tabla_sitios table {
    width: 200px;
  }

  .places_icons #geo_12 {
    position: absolute;
    top: -110px;
    left: -80px;
    color: #5E2129 !important;
  }

  .places_icons #geo_12:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_13 {
    position: absolute;
    top: -130px;
    left: -140px;
    color: #5E2129 !important;

  }

  .places_icons #geo_13:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_14 {
    position: absolute;
    bottom: 40px;
    left: 90px;
    color: #5E2129 !important;

  }

  .places_icons #geo_14:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_15 {
    position: absolute;
    bottom: -80px;
    left: -110px;
    color: #5E2129 !important;
  }

  .places_icons #geo_16 {
    position: absolute;
    bottom: 10px;
    left: -90px;
    color: #5E2129 !important;
    font-size: 20px !important;
  }

  .places_icons #geo_15:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

  .places_icons #geo_16:hover {
    transform: scale(1.2);
    transition: 0.5s;
  }

}

.filtro {
  display: none;
}

.select {
  display: block;
}

.u-virtual {
  background-color: #845dc3;
  text-align: center !important;
}

.base {
  position: relative;
}

::placeholder {
  color: black;
}

.__web-inspector-hide-shortcut__,
.__web-inspector-hide-shortcut__ *,
.__web-inspector-hidebefore-shortcut__::before,
.__web-inspector-hideafter-shortcut__::after {
  visibility: visible !important;
}

.places_icons i {
  cursor: pointer;
}

.tabla_sitios table {
  margin-bottom: 0 !important;
}

.tabla_sitios table .bodytable {
  display: none;
}


.tabla_sitios .tablaBuscador {
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-radius: 5px;
}


.containerMapa {
  background-color: #748E24;
  max-width: 2000px;
  margin: auto;
}



.tabla_contenido table {
  height: 20px !important;
}

.contenido_tabla {
  height: 200px;
  overflow: scroll;
}

.table>:not(caption)>*>* {

  border-bottom-width: 0px !important;

}

.tabla_contenido {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
}



.contenido_tabla th {
  width: 300px;
  border-bottom: 1px solid black;
  border-radius: 0 !important;
}

.tabla_sitios table {
  background-color: white;
  border-radius: 10px;
}