.personasPrincipal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.personas_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 120px;
  max-width: 1400px;

}

.personas_container li {
  list-style: none;
}

.personas_cont {
  background-image: url("../img/Recursos/fondopersonas.png");
  background-size: 100% 100%;
  max-width: 2000px;
  margin: auto;
}

.personas_cont h1 {
  text-align: center;
  color: #76383d
  ;
  font-size: 60px !important;
  letter-spacing: 5px;
  text-shadow: 2px 2px 3px black;
  padding-top: 100px;
  font-weight: bold;
}

@media (min-width: 200px) and (max-width: 600px) {
  .personas_cont2 h1 {
    font-size: 35px !important;
  }

  .container_OfertaVirtual .containerTituloOferta a {
    font-size: 20px !important;
  }
}

.cardCompleta {
  position: relative;
  width: fit-content;
  margin: 40px;
  transition: 2s;
}

.cardCompleta .imagen img {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 25px;
  z-index: 2;
}

.cardCompleta .imagen .img_biblioteca{
  filter: brightness(1.3);
}

.cardCompleta .imagen::before {
  position: absolute;
  content: "";
  border-radius: 25px;
  border: white 1px solid;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3px -3px -1px -3px;
  z-index: 1;
  opacity: 0.5;
}



.cardCompleta .nombre a {
  position: relative;
  color: black;
  padding: 20px;
  width: 250px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px black;
  font-size: 15px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  z-index: 3;
  user-select: none;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardCompleta .nombre a::before {
  position: absolute;
  content: "";
  border-radius: 10px;
  border: white 1px solid;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3px;
  opacity: 0.5;
}

.container_card {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.cardCompleta {
  color: white;
}

.cardCompleta {
  cursor: pointer;
}

.cardCompleta:hover {
  transform: scale(1.05);
  transition: 1s;
}

.nombre {
  width: 100%;
  position: absolute;
  bottom: -50px;
  display: flex;
  justify-content: center;
}

ul:not(.browser-default) > li {
  text-align: center;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardCompleta .nombre .amarillo:hover {
  background-color: white !important;
  color: #b28532;
}

.cardCompleta .nombre .verde:hover {
  background-color: white !important;
  color: #6d803a;
}

.cardCompleta .nombre .aqua:hover {
  background-color: white !important;
  color: #429b85;
}


.cardCompleta .nombre .azul:hover {
  background-color: white !important;
  color: #10344c;
}

.cardCompleta .nombre .rojo:hover {
  background-color: white !important;
  color: #76383d;
}

.cardCompleta .nombre .morado:hover {
  background-color: white !important;
  color: #514172;
}



.cardCompleta .imagen img {
  box-shadow: rgba(0, 0, 0, 0.600) 0px 3px 10px, rgba(0, 0, 0, 0.678) 0px 3px 6px;}