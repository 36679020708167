.sidenav i {
  background-color: purple;
  padding: 5px;
  width: fit-content;
  margin: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 25px;
  color: white;
}

.sidenav i:hover {
  color: rgb(255, 255, 255, 0.7);
}

.container_nav_slider {
  position: sticky;
  top: 0;
  display: none;
  z-index: 2020;
  height: 0px;
}


.personas_container2 {
  background-image: url("../img/Recursos/fondopersonas.png");
  background-size: 100% 100%;
  height: 600px;

}

.sidenav{
  height: 100% !important;
}


.user-view p{
  color: red;
}


.deco{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  width: 100%;
}

.deco li{
  width: 80%;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: flex-start !important;
  background-color: transparent !important;
}

.sidenav i{
  background-color: transparent;
  color: black;
}



.sliderNav:hover{
  color: black !important;
  background-color: white !important;
}

.offcanvas{
  width: 300px !important;
}

.offcanvas-body {
  padding: 0 !important;
}

dl, ol, ul {
  margin-top: 0;
   margin-bottom: 0;
}

ol, ul {
  padding-left: 0;
}