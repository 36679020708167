.containerAyuda {
  z-index: 999;
  height: 100%;
  max-width: 100vw;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ) !important;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -mos-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.ContainerSliderAyuda {
  display: none;
  height: auto;
  position: fixed !important;
  top: 0;
  right: 0;
  margin-top: 25vh;  
  z-index: 999;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  width: 210px;
  background-color: #ffffff;
}

.ContainerSliderAyuda button {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  font-size: 13px;
  text-align: start;
  border: none;
    color: #797979 ;

}

.ContainerSliderAyuda i {
  margin-right: 15px;
}

.ContainerSliderAyuda i{
  color: black  !important;
}

.ContainerSliderAyuda::-webkit-scrollbar {
  -webkit-appearance: none;
}

.ContainerSliderAyuda::-webkit-scrollbar:vertical {
  width:10px;
}

.ContainerSliderAyuda::-webkit-scrollbar-button:increment,.ContainerSliderAyuda::-webkit-scrollbar-button {
  display: none;
} 

.ContainerSliderAyuda::-webkit-scrollbar:horizontal {
  height: 10px;
}

.ContainerSliderAyuda::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.ContainerSliderAyuda::-webkit-scrollbar-track {
  border-radius: 10px;  
}

.accesibilidad{
  font-size: 1.3em;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;

}

.cerrar{
  text-align: center !important;
  color: white !important;
  font-weight: bold;
  font-size: 1.1em !important;
  background-color: #ff6961  !important;
  width: 100% !important;
  border-top: 1px solid black !important;
}

.accesibilidad{
  height: auto;
}


::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width:10px;
}

::-webkit-scrollbar-button:increment,::-webkit-scrollbar-button {
  display: none;
} 

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

::-webkit-scrollbar-track {
  border-radius: 10px;  
}


.fontsize{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.fontsize button {
  text-align: center;
  box-shadow: 0  0 1px black;
}

.active-gray{
  filter: grayscale(100%);
}

