@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'opendyslexic';
	src: url(U-Virtual/img/Dine-ExtraLight.woff);
} 


h1{
  font-family: "Poppins", sans-serif !important;

}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

body {
  margin: 0;
  overflow-x: hidden;
}


.active-font{
  font-family: 'opendyslexic' !important;
}

#root{
  overflow: hidden;
}