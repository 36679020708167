
  

  @media (min-width: 801px) {
    .contactos_fijos {
        height: 300px;
        background-color: #2885ce;
        max-width: 2000px;
        margin: auto;
        margin-left: auto;
        margin-right: auto;
      }
    
      ul {
        list-style: none;
      }
      
      .container_contacto_fijos {
        max-width: 1400px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    
      .contactos_items {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }
    
      .contactos_fijos ul:not(.browser-default) > li {
        text-align: center;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
    }
      
      .contactos_items li {
        color: white;
        margin: 10px;
        font-size: 17px;
      }
      
      .contactos_items i {
        margin-right: 20px;
      }
      
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .img img {
        height: 170px;
      }
  }
  
  @media (min-width: 300px) and (max-width: 800px) {
    .contactos_fijos {
        height: 300px;
        background-color: #4385CE;
      
        margin-left: auto;
        margin-right: auto;
      }
    
      .padding_boton {
        margin-top: 20px;
      }
      ul {
        list-style: none;
      }
      
      .container_contacto_fijos {
        max-width: 1400px;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
     
      }
    
      .contactos_items {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
      }
    
      .contactos_fijos ul:not(.browser-default) > li {
        text-align: center;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
    }
      
      .contactos_items li {
        color: white;
        margin: 10px;
        font-size: 14px;
      }
      
      .contactos_items i {
        margin-right: 20px;
      }
      
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .img img {
        margin-top: 20px;

        height: 80px;
      }
  }
  
  .datos .title1 {
    cursor: default;
  }
  
  .datos .title2 {
    cursor: default;
  }
  
  .uk-link-toggle:hover .uk-link, .uk-link:hover, a:hover {
     text-decoration: none ;
  }