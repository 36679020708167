@media (min-width: 300px) and (max-width: 520px) {

  .items_card3 {
    margin-right: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    justify-items: center;
    align-items: center;
    height: 1200px;
    max-width: 100%;
    padding-bottom: 20px;
  }

  .items_card3 .cardCompleta {
    margin-bottom: 90px;
  }

  .facultadesContainer {

    background-image: url("../img/Recursos/fondofac.png");
    background-size: 100% 100%;
    height: auto;

  }

  .cardCompleta .nombre_facultad a {
    position: relative;
    color: black;
    padding: 10px;
    width: 210px;
    text-align: center;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px black;
    font-size: 15px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    z-index: 3;
    user-select: none;
  }

  .cardCompleta .nombre_facultad a::before {
    position: absolute;
    content: "";
    border-radius: 20px;
    border: white 2px solid;
    opacity: 0.3;
    left: 0;
    top: 0;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -5px;
  }

  .nombre_facultad {
    width: 100%;
    position: absolute;
    bottom: -30px;
    display: flex;
    justify-content: center;
  }

  .containerTituloOferta2 a {
    position: relative;
    padding: 20px;
    font-size: 40px;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 5px black;
    margin-top: 80px;
  }

  .containerTituloOferta2 {
    text-align: center;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;

  }


  .items_card3 .cardCompleta .imagen img {
    position: relative;
    width: 230px;
    height: 230px;
    border-radius: 25px;
    z-index: 2;
  }


  .items_card3 .cardCompleta {
    margin-bottom: 30px;
  }

  .containerTituloOferta2 p {
    position: relative;
    padding: 10px;
    font-size: 40px;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 5px black;
  }

}

@media (min-width: 521px) {

  .items_card3 {
    margin-right: 10px;
    display: flex;
    justify-items: center;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }

  .items_card3 .cardCompleta {
    margin-bottom: 90px;
  }

  .facultadesContainer {
    background-image: url("../img/Recursos/fondofac.png");
    background-size: 100% 100%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 2000px;
    margin: auto;
  }




  .containerTituloOferta2 p {
    position: relative;
    font-size: 40px;
    color: white;
    font-weight: bold;
    text-shadow: 3px 3px 10px #000000;
    margin-top: 30px;
    user-select: none;
    letter-spacing: 5px;
  }

  .containerTituloOferta2 {
    text-align: center;
    display: flex;
    margin-bottom: 0;
    justify-content: center;
    align-items: center;
  }

  .container_card {
    padding-bottom: 75px;

  }

  .items_card3 .cardCompleta .imagen img {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 25px;
    z-index: 2;
  }


  .items_card3 .cardCompleta {
    margin-bottom: 30px;
  }

}

.cardCompleta .imagen.imgfacultad img {
  box-shadow: none !important
}

.cardCompleta .imagen.imgfacultad::before {
  position: absolute;
  content: "";
  border-radius: 25px;
  border: none !important;
  left: 0;
  top: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -3px -3px -1px -3px;
  z-index: 1;
  opacity: 0.5;
}

.facultadesContainer .cardCompleta {
  margin: 20px !important;
}

.imgfacultad p{
  position: absolute;
  width: 100%;
  top: -10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  padding: 20px;
  font-weight: 600;
}