:root {
  --font-size: 1;
}



/* body {
  font-size: calc(var(--font-size) * 1em) !important;
} */

.App {
  text-align: center;
  max-width: 100%;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.WaveFinal {
  transform: rotate(180deg);
}

.wave_img {
  fill: transparent;
}

@media (min-width: 300px) and (max-width: 730px) {
  .personas_cont {
    display: none;
  }
}

@media (min-width: 731px) {
  .personas_cont2 {
    display: none;
  }
}


.container_iframe iframe {
  width: 100%;
  height: 80vh;
}

@media (min-width: 1300px) and (max-width: 2500px) {
  .container_iframe iframe {

    height: 90vh;
  }
}

@media (min-width: 800px) and (max-width: 1299px) {
  .container_iframe iframe {
    height: 90vh;
  }
}

.Mapa_titulo {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  max-width: 1400px;
  margin-right: auto;
}
.Titulo_mapa {
  text-align: start;
  margin: 0;
  font-weight: bold;
  color: #e75962;
  font-size: 5rem !important;
}

.Titulo_mapa2 {
  text-align: start;
  margin-left: 50px;
  font-weight: bold;
  color: #e75962;
  font-size: 7rem !important;
  margin-bottom: 0 !important;
}

@media (min-width: 200px) and (max-width: 500px) {
  .Titulo_mapa {
    text-align: start;
    margin: 0;
    font-weight: bold;
    color: #e75962;
    font-size: 3rem !important;
  }
  
  .Titulo_mapa2 {
    text-align: start !important;
    margin-left: 50px;
    font-weight: bold;
    color: #e75962;
    font-size: 5rem !important;
    margin-bottom: 0 !important;
  }

  .containerVicerrectoria{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    flex-direction: column !important;
  }

  .containerVicerrectoria ul{
    width: 100% !important;
  }

  .tablaVice{
    margin-left: 0 !important;
  }

  .containerIdiomas
  {
    flex-direction: column;
  }

  .listaIdiomas{
    width: 100% !important;
  }

  .tablaIdiomas{
    font-size: 10px;
  }

  #tituloServicios{
    padding: 0 ;
  }
}

.modal-backdrop.show {
     opacity: 0 !important; 
}

.modal-backdrop{
  top: auto  !important;
}

.active-contrast{
filter:  contrast(1.75);
}

.active-saturacion{
  filter: invert(1);
}

.item_pestaña{
  padding: 10px;
}

.item_pestaña:hover{
  background-color: #d1cece;
  border-radius: 20px;
}

.pestañas{
  background-color: #f6f6f6;
}

.vice_Card .card{
  height: 125px !important;
}

.card-text{
  font-size: 2rem;
  text-shadow: 1px 1px 3px black;
  border-bottom: 3px solid rgb(255, 208, 0);
}

.container td{
  text-align: center;
}


 @media (min-width: 1800px) { 

    .OlaFacultad{
      height: 350px !important;
    }
  }


  .linea_active{
    background-color: #F08080 !important;
  }

  .linea_active2{
    text-decoration: underline;
  }

  .cartaPosicion{
    position: absolute;
    top: 120px;
    left: 10px;
  }

  .cartaContenedor{
    width: 360px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .cartaImagenContenedor{
    border: 5px solid rgb(255, 255, 255);
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    top: -70px;
    border-radius: 50%;
    background-color: wheat;
  }

  .cartaImagenContenedor img{
    width: 200px;
    height: 200px;
  }

  .cartaDescripcion{
    margin-top: 80px;
    text-align: justify;
    font-size: 12px;
    color: white;
  }

  .cartaPosicion#buenanueva{
    opacity: 0;
    transition: .7s;
  }

  .cartaPosicion#Dabarcarta{
    opacity: 0;
    transition: .7s;
  }

  
  .cartaPosicion#Kabaicarta{
    opacity: 0;
    transition: .7s;
  }

  .cartaPosicion#Alecarta{
    opacity: 0;
    transition: .7s;
  }

  .cartaPosicion#Humacarta{
    opacity: 0;
    transition: .7s;
  }


  .cartavisible{
    opacity: 1 !important;
  }

